import { useCallback, useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  Center,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement, useToast
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { LOGIN_REQUEST, LOGIN_REQUEST_SUCCESS, LOGIN_REQUEST_FAIL } from "../redux/authReducer/actionType";
import sound from './assets/next-option.mp3'
import success from './assets/success.mp3'
import axios from "axios"
import { LoginAction, } from "../redux/authReducer/action";
const base_url = process.env.REACT_APP_BACKEND_URL;
const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);
const Login = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const toast = useToast()
  const navigate = useNavigate();
  
  const handleShowClick = () => setShowPassword(!showPassword);

  const LoginAction = async (email, password) => {

    try {
      dispatch({ type: LOGIN_REQUEST });
      const config = { headers: { 'Content_Type': 'application/json' } }

      const { data } = await axios.post(`/api/adminlogin`, { email, password }, config)

      console.log(data);
      toast({
        title: data.message,
        status: 'info',
        duration: 2000,
        isClosable: true,
      });

      const audio = new Audio(success);
      audio.play();
      navigate('/admin/dashboard'); 


      dispatch({ type: LOGIN_REQUEST_SUCCESS, payload: data });





    }
    catch (error) {
      dispatch({ type: LOGIN_REQUEST_FAIL });

       const audio = new Audio(sound);
      audio.play();
    };
  };

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    LoginAction(email, password);


  }, [email, password])





  const backgroundImageUrl = 'https://images.pexels.com/photos/1103970/pexels-photo-1103970.jpeg';
  return (
    <Box
      bgGradient='linear(to-r, blue.800, blue.500)'
      h={'100vh'}
      justifyContent={'center'}
      // p={10}



      flexDirection="column"
      width="90wh"

      backgroundColor="#fff5ee"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        bgGradient='linear(to-r, navy, gray.600)'
        p={6}
        mb="2"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Avatar bg="orange" />
        <Heading color="orange" mb={2} fontSize={'25'}>Login</Heading>
        <Box minW={{ base: "70%", md: "384px" }}>
          <form onSubmit={handleSubmit} >
            <Center>
              <Stack
                spacing={5}

                p="9"
                // borderColor={'orange'}
                borderWidth={'2px'}
                borderRadius={20}
                // backgroundColor="blue.900"
                boxShadow="lg"
                h={'250'}
              >
                {/* <Heading textAlign={'center'} color="blue.600" fontSize={'25'}>Login</Heading> */}
                <FormControl>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<CFaUserAlt color="blue" />}
                    />
                    <Input type="email" borderRadius={2} onChange={(e) => { setEmail(e.target.value) }} fontSize={'13'} placeholder="Email " />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      children={<CFaLock color="blue" />}
                    />
                    <Input borderRadius={2} onChange={(e) => { setPassword(e.target.value) }}
                      type={showPassword ? "text" : "password"}
                      fontSize={'13'}
                      placeholder="Password"
                    />
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" color={'gray'} variant={'ghost'} onClick={handleShowClick}>
                        {showPassword ? <ViewOffIcon boxSize={5} /> : <ViewIcon boxSize={5} />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <FormHelperText textAlign="right" color={'gray'}>
                    {/* <Link>forgot password?</Link> */}
                  </FormHelperText>
                </FormControl>
                <Button
                  borderRadius={4}
                  borderColor={'gray'}
                  // borderWidth={'2px'}
                  type="submit"
                  // variant="outline"
                  bg={'orange'}
                  color={'white'}
                  width="full"
                  onClick={handleSubmit}
                  fontWeight={'bold'}
                >
                  Login
                </Button>
              </Stack>
            </Center>
          </form>
        </Box>
      </Stack>

    </Box>
  );
};

export default Login;
