import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import App2 from './App2';
import AboutUs from './website/pages/AboutUs';
import Header from './website/pages/Header';
import Navbar from './website/pages/Navbar';
import Menubar from './website/pages/Menubar';
import Footer from './website/pages/Footer';
import { ChakraProvider } from '@chakra-ui/react';


const App3 = () => {
  return (
    // <BrowserRouter>
    <ChakraProvider>
    <Header/>
      <Navbar/>
      <Menubar/>
    <Routes>
      <Route path="/" element={<App2 />} />
      {/* <Route path="/about" element={<AboutUs />} /> */}
      {/* Add more internal routes as needed */}
    </Routes>
      <Footer/>
      {/* // </BrowserRouter> */}
      </ChakraProvider>
  );
};

export default App3;
