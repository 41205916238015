import React from 'react';
import { Box, Heading, Text, VStack, HStack, Image, Flex, StackDivider } from "@chakra-ui/react";
import { FaTrophy, FaAward, FaMedal } from 'react-icons/fa';
import AboutPage from "./AboutPage";

const ImageWithTextOverlay = ({ imageUrl, heading, description }) => (
  <Box 
    bgImage={`url(${imageUrl})`}
    bgSize="cover"
    bgPosition="center"
    bgRepeat="no-repeat"
    position="relative"
    borderRadius="md"
    overflow="hidden"
    height="500px"
    width="100%"
  >
    <Box 
      position="absolute"
      bottom={0}
      left={0}
      right={0}
      bg="rgba(0, 0, 0, 0.6)"
      color="white"
      p={4}
      textAlign="center"
    >
      <Text fontSize="5xl" fontWeight="bold" mb={2}>{heading}</Text>
    </Box>
  </Box>
);

const AboutUs = () => (
  <Box>
    <Flex justify="center" align="center" direction="column">
      <ImageWithTextOverlay
        imageUrl="https://jooinn.com/images/coding-and-programming-computer-science-and-it.jpg"
        heading="ABOUT US"
        description="A stunning view of the sunset over the ocean."
      />
    </Flex>
    <AboutPage />


    <Box p={["4", "6", "8"]} maxW="100%" mx="auto" color={'#003366'} fontWeight={'450'}>
      <Box p={4}>
        <Heading as="h2" size="md" mb="2" fontSize={["lg", "xl"]} color={'#4169e1'} textAlign="center">
          <HStack justify="center">
            <FaTrophy />
            <Text>About the Training Program</Text>
          </HStack>
        </Heading>
        <Text mb="4" fontSize={["sm", "md", "lg"]} fontWeight={'500'} textAlign="center">
          Our comprehensive training covers MongoDB, Express.js, React, and Node.js. The course is structured into various modules, each focusing on different aspects of full-stack development. The duration of the program is 12 weeks, with flexible schedules to accommodate working professionals.
        </Text>
        
        <Heading as="h2" size="md" mb="2" fontSize={["lg", "xl"]} color={'#4169e1'} textAlign="center">
          <HStack justify="center">
            <FaAward />
            <Text>Benefits of the Training</Text>
          </HStack>
        </Heading>
        <Text mb="4" fontSize={["sm", "md", "lg"]} fontWeight={'500'} textAlign="center">
          Upon completing this training, you will gain in-depth knowledge of full-stack development, enabling you to build complex web applications. Our certification is recognized by top tech companies, opening up numerous career opportunities.
        </Text>
        
        <Box mb={4} fontSize={["sm", "md",]} opacity={'500'} fontWeight={'600'}>
          1. Comprehensive Skill Development: Participants will gain expertise in modern JavaScript technologies, MERN stack development, and exposure to AI/ML concepts.<br/>
          2. Hands-on Experience: Practical exercises and real-world projects will provide invaluable hands-on experience.<br/>
          3. Mentorship: Guidance from experienced professionals will help participants navigate challenges and accelerate their learning.<br/>
          4. Employment Opportunities: Successful completion of the program will open doors to employment opportunities within the company or elsewhere in the industry.
        </Box>
        
        <Heading as="h2" size="md" mb="2" fontSize={["lg", "xl"]} color={'#4169e1'} textAlign="center">
          <HStack justify="center">
            <FaMedal />
            <Text>Benefits for the Company</Text>
          </HStack>
        </Heading>
        <Box mb={4} fontSize={["sm", "md",]} opacity={'500'} fontWeight={'600'}>
          1. Talent Pipeline: Access to a pool of skilled graduates with relevant expertise in JavaScript-based technologies and AI/ML.<br/>
          2. Cost-Effective Training: The training fee structure ensures that the program is financially sustainable for the company.<br/>
          3. Quality Output: Participants will contribute to company projects during the paid task period, providing quality output under supervision.
        </Box>
        
        <Heading as="h2" size="md" mb="2" fontSize={["lg", "xl"]} color={'#4169e1'} textAlign="center">
          <HStack justify="center">
            <FaAward />
            <Text>Conclusion</Text>
          </HStack>
        </Heading>
        <Box mb={4} fontSize={["sm", "md", "lg"]} textAlign="center" opacity={'500'} fontWeight={'600'}>
          This proposed training program offers a structured pathway for college graduates to develop proficiency in JavaScript-based technologies, MERN stack development, and AI/ML concepts. By investing in the training and development of fresh talent, the company stands to benefit from a skilled workforce ready to tackle modern software development challenges. We look forward to implementing this program and fostering the growth of aspiring developers in the industry.
        </Box>
      </Box>

    </Box>
  </Box>
);

export default AboutUs;
