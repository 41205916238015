import React, { useState } from 'react';
import { 
  ChakraProvider, 
  Box, 
  Flex, 
  Text, 
  IconButton, 
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  Button, 
  Drawer, 
  DrawerBody, 
  DrawerFooter, 
  DrawerHeader, 
  DrawerOverlay, 
  DrawerContent, 
  DrawerCloseButton, 
  useDisclosure, 
  Stack, 
  Input, 
  HStack 
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { FaHome, FaInfoCircle, FaBook, FaLaptop, FaCog, FaHeadset } from 'react-icons/fa';
import { GrServices } from "react-icons/gr";
import Main3 from './Main3';

function Menubar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showSearch, setShowSearch] = useState(false);

  return (
    <ChakraProvider>
      <Box  justifyContent={'center'}  borderWidth={'1px'}>
        <Flex alignItems="center" justifyContent="center" bg={'#0072bb '}>
          <Flex display={{ base: 'none', md: 'flex' }} gap={5} alignItems="center" justifyContent={'center'}>
            <Link to={'/'} >
              <HStack color="white" as='button' p={3} fontWeight={'600'} _hover={{ color: 'white', bg: 'navy', fontWeight: 600 }}>
                <FaHome />
                <Text>HOME</Text>
              </HStack>
            </Link>

            <Link to={'/about'} >
              <HStack color="white" as='button' p={3} fontWeight={'600'} _hover={{ color: 'white', bg: 'navy', fontWeight: 600 }}>
                <FaInfoCircle />
                <Text>ABOUT US</Text>
              </HStack>
            </Link>
            
            <Link to={'/courses'} >
              <HStack color="white" as='button' p={3} fontWeight={'600'} _hover={{ color: 'white', bg: 'navy', fontWeight: 600 }}>
                <FaBook />
                <Text>COURSES</Text>
              </HStack>
            </Link>
            
            <Link to={'/online_training'} >
              <HStack color="white" as='button' p={3} fontWeight={'600'} _hover={{ color: 'white', bg: 'navy', fontWeight: 600 }}>
                <FaLaptop />
                <Text>ONLINE TRAINING</Text>
              </HStack>
            </Link>

            <Link to={'/services'} >
              <HStack color="white" as='button' p={3} fontWeight={'600'} _hover={{ color: 'white', bg: 'navy', fontWeight: 600 }}>
                <FaCog />
                <Text>SERVICES</Text>
              </HStack>
            </Link>
            
            <Link to="/contact">
              <HStack color="white" as='button' p={3} fontWeight={'600'} _hover={{ color: 'white', bg: 'navy', fontWeight: 600 }}>
                <FaHeadset />
                <Text>SUPPORT</Text>
              </HStack>
            </Link>
          </Flex>
          
          <Flex alignItems="center">
            {showSearch && (
              <Input
                type="text"
                
                placeholder="Search..."
                size="sm"
                color={'white'}
                borderRadius={'full'}
                ml={4}
                borderColor="gray.200"
              />
            )}
            <IconButton
              icon={<SearchIcon />}
              bg={'ghost'}
              color={'white'}
              aria-label="Search"
              onClick={() => setShowSearch(!showSearch)}
              ml={2}
            />
            <IconButton
              size="md"
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label="Open Menu"
              display={{ md: 'none' }}
              onClick={isOpen ? onClose : onOpen}
              ml={2}
            />
          </Flex>
        </Flex>

        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
        >
          <DrawerOverlay>
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerBody>
                <Stack as="nav" spacing={4}>
                  <Link to={'/'} onClick={onClose}>
                    <HStack color="white" as='button' bg={'gray.200'} p={3} fontWeight={'600'} _hover={{ color: 'white', bg: 'navy', fontWeight: 600 }}>
                      <FaHome />
                      <Text>HOME</Text>
                    </HStack>
                  </Link>
                  
                  <Link to={'/about'} onClick={onClose} >
                    <HStack color="white" as='button' bg={'gray.200'} p={3} fontWeight={'600'} _hover={{ color: 'white', bg: 'navy', fontWeight: 600 }}>
                      <FaInfoCircle />
                      <Text>ABOUT US</Text>
                    </HStack>
                  </Link>
                  
                  <Link to={'/courses'}  onClick={onClose}>
                    <HStack color="white" as='button' bg={'gray.200'} p={3} fontWeight={'600'} _hover={{ color: 'white', bg: 'navy', fontWeight: 600 }}>
                      <FaBook />
                      <Text>COURSES</Text>
                    </HStack>
                  </Link>
                  
                  <Link to={'/online_training'} onClick={onClose} >
                    <HStack color="white" as='button' bg={'gray.200'} p={3} fontWeight={'600'} _hover={{ color: 'white', bg: 'navy', fontWeight: 600 }}>
                      <FaLaptop />
                      <Text>ONLINE TRAINING</Text>
                    </HStack>
                  </Link>

                  <Link to={'/services'} onClick={onClose} >
                    <HStack color="white" as='button' bg={'gray.200'} p={3} fontWeight={'600'} _hover={{ color: 'white', bg: 'navy', fontWeight: 600 }}>
                      <FaCog />
                      <Text>SERVICES</Text>
                    </HStack>
                  </Link>

                  <Link to="/contact" onClick={onClose}>
                    <HStack color="white" as='button' bg={'gray.200'} p={3} fontWeight={'600'} _hover={{ color: 'white', bg: 'navy', fontWeight: 600 }}>
                      <FaHeadset />
                      <Text>SUPPORT</Text>
                    </HStack>
                  </Link>
                </Stack>
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
        <Flex>

<Main3/>

        </Flex>
      </Box>
    </ChakraProvider>
  );
}

export default Menubar;
