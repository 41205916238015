import React, { useEffect } from 'react';
import { useDisclosure, Icon, Image, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, Box, Text, Flex, HStack, VStack, Button } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { MdAdminPanelSettings, MdDashboard } from "react-icons/md";
import { IoSettings } from "react-icons/io5";
import { Link, Outlet,useNavigate } from 'react-router-dom';
import { FaUniversity } from "react-icons/fa";
import { PiStudentBold } from "react-icons/pi";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { RiFindReplaceFill } from "react-icons/ri";
import { AiFillProfile } from "react-icons/ai";
import { HiUserGroup } from "react-icons/hi2";
import { useSelector, useDispatch, } from 'react-redux';
import { LogoutuserAction } from '../redux/authReducer/action';
import logo from './assets/image.png';
import Main from './Main';

const Navbar2 = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isAuthenticated } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
const navigate=useNavigate()
  const handleLogout = async () => {
    dispatch(LogoutuserAction());

    navigate('/');


  };


// useEffect(()=>{
// navigate('dashboard');

// },[])





  const SidebarContent = () => (
    <VStack spacing="4" align="stretch" pl={8}>
      <Box as={Link} to="/dashboard" _hover={{ bg: "blue.100", color: 'blue.500' }} p={2} rounded="md">
        <HStack color={'blue.900'}>
          <Icon as={MdDashboard} boxSize={'4'}  />
          <Text fontWeight={'700'} fontSize={'15'} _hover={{ color: 'blue.500' }}>Dashboard</Text>
        </HStack>
      </Box>
      {/* <Box as={Link} to="/manage_student" _hover={{ bg: "blue.100" }} p={2} rounded="md">
        <HStack color={'blue.900'}>
          <Icon as={PiStudentBold} boxSize={'4'} _hover={{ color: 'blue.500' }} />
          <Text fontWeight={'700'} fontSize={'15'} _hover={{ color: 'blue.500' }}>Student</Text>
        </HStack>
      </Box>
      <Box as={Link} to="/manage_users" _hover={{ bg: "blue.100" }} p={2} rounded="md">
        <HStack color={'blue.900'}>
          <Icon as={HiUserGroup} boxSize={'4'} _hover={{ color: 'blue.500' }} />
          <Text fontWeight={'700'} fontSize={'15'} _hover={{ color: 'blue.500' }}>Users</Text>
        </HStack>
      </Box> */}
      {/* <Box as={Link} to="/manage_course" _hover={{ bg: "blue.100" }} p={2} rounded="md">
        <HStack color={'blue.900'}>
          <Icon as={AiFillProfile} boxSize={'4'} _hover={{ color: 'blue.500' }} />
          <Text fontWeight={'700'} fontSize={'15'} _hover={{ color: 'blue.500' }}>Course</Text>
        </HStack>
      </Box> */}
      {/* <Box as={Link} to="/profile" _hover={{ bg: "blue.100" }} p={2} rounded="md">
        <HStack color={'blue.900'}>
          <Icon as={MdAdminPanelSettings} boxSize={'5'} _hover={{ color: 'blue.500' }} />
          <Text fontWeight={'700'} fontSize={'15'} _hover={{ color: 'blue.500' }}>Profile</Text>
        </HStack>
      </Box>
      <Box as={Link} to="/setting" _hover={{ bg: "blue.100" }} p={2} rounded="md">
        <HStack color={'blue.900'}>
          <Icon as={IoSettings} boxSize={'4'} _hover={{ color: 'blue.500' }} />
          <Text fontWeight={'700'} fontSize={'15'} _hover={{ color: 'blue.500' }}>Setting</Text>
        </HStack>
      </Box> */}
      {isAuthenticated && (
        <Box as='button' _hover={{ bg: "blue.100" }} p={2} rounded="md" onClick={handleLogout}>
          <HStack color={'blue.900'} spacing={2}>
            <Icon as={RiLogoutCircleRLine} boxSize={'5'} _hover={{ color: 'blue.500' }} />
            <Text fontWeight={'700'} fontSize={'15'} _hover={{ color: 'blue.500' }}>Logout</Text>
          </HStack>
        </Box>
      )}
    </VStack>
  );

  return (
    <Flex>
      <Box w={{ base: 'full', md: '220px' }} bg="white" borderWidth="2px" p={2} justifyContent={'center'} alignItems={'center'}>
        <Image src={logo} alt='logo' p={5} />
        <Box display={{ base: 'none', md: 'block' }}>
          <SidebarContent />
        </Box>
        <Box display={{ base: 'block', md: 'none' }} onClick={onOpen}>
          <HamburgerIcon boxSize={'6'} />
        </Box>
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay>
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>
                <Image src={logo} alt='logo' p={5} />
              </DrawerHeader>
              <DrawerBody>
                <SidebarContent />
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </Box>
      <Box flex="1">
       <Flex>
         <Main/>
         <Outlet />
       </Flex> 
     </Box> 
    </Flex>
  );
};

export default Navbar2;
