import {applyMiddleware, combineReducers, createStore,compose} from "redux"
import {thunk} from "redux-thunk";
import {reducer as authReducer} from "./authReducer/reducer";
const rootReducer = combineReducers({
  
  authReducer
 
  }); 
  
const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;




  export const store = createStore(rootReducer,composeEnhancers(applyMiddleware(thunk)));

