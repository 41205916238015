// Carousel.js
import React, { useEffect, useState } from 'react';
import { Box, Image, Text, IconButton, VStack } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

const images = [
  {
    src: 'https://stackpos.in/assets/images/banner-(2).jpg',
    texts: ['MERN STACK TRAINING & INTERNSHIP PROGRAM', 'Learn the web developement tools and frameworks to become an Web Developer', ]
  },
  {
    src: 'https://stackpos.in/assets/images/banner-(1).jpg',
    texts: ['MERN STACK TRAINING & INTERNSHIP PROGRAM', 'Learn the web developement tools and frameworks to become an Web Developer', ]
  },
  {
    src: 'https://stackpos.in/assets/images/banner-(3).jpg',
    texts: ['MERN STACK TRAINING & INTERNSHIP PROGRAM', 'Learn the web developement tools and frameworks to become an Web Developer', ]
  }
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000); // Change image every 3 seconds
    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [currentIndex]);

  return (
    <Box position="relative" w="100%" mx="auto">
      <IconButton
        aria-label="Previous Slide"
        icon={<ChevronLeftIcon />}
        bg="transparent"
        position="absolute"
        left="0"
        top="50%"
        transform="translateY(-50%)"
        zIndex="2"
        onClick={prevSlide}
      />
      <Image src={images[currentIndex].src} alt={`Slide ${currentIndex + 1}`} w="100%" h={{ base: '200px', md: '400px', lg: '530px' }} objectFit="cover" />
      <VStack
        position="absolute"
        bottom="0"
        top="0"
        left="50%"
        transform="translateX(-50%)"
        w="100%"
        h="100%"
        spacing={2}
        color="white"
        fontSize={{ base: '20px', md: '35px', lg: '50px' }}
        fontWeight="600"
        bg="rgba(0, 0, 0, 0.5)"
        textAlign="center"
        justifyContent="center"
        p="15px"
      >
        {images[currentIndex].texts.map((text, index) => (
          <Text key={index}>{text}</Text>
        ))}
      </VStack>
      <IconButton
        aria-label="Next Slide"
        icon={<ChevronRightIcon />}
        position="absolute"
        bg="transparent"
        right="0"
        top="50%"
        transform="translateY(-50%)"
        zIndex="2"
        onClick={nextSlide}
      />
    </Box>
  );
};

export default Carousel;
