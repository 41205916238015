import {   List, ListItem, Box, Image, Text, VStack,Icon, Heading, ListIcon,Flex ,StackDivider, Avatar, SimpleGrid, GridItem, HStack} from "@chakra-ui/react";
import { BsFillSquareFill } from 'react-icons/bs';
import { AiFillClockCircle } from 'react-icons/ai';
import { FiPhoneCall } from "react-icons/fi";
import { PiStudentBold } from "react-icons/pi";
import { FcManager } from "react-icons/fc";
import { HiMiniWallet } from "react-icons/hi2";
import { Link } from "react-router-dom";

const CourseModule = ({ imageName, moduleName, moduleDescription, duration }) => (
  <Box border="1px solid #ccc" p={4} mb={4} mr={1} boxShadow={'md'}  width={{ base: "100%", md: "50%", lg: "33%" }}>
    <Flex direction="column" align="center" justify="center">
      <Box>

      <Image src={imageName} alt={moduleName}  mb={4} />
      </Box>
      <Heading as="h3" size="md" textAlign="center" mb={2}>{moduleName}</Heading>
      <Text textAlign="center" mb={2}>{moduleDescription}</Text>
      <Flex align="center" justify="center"  mb={3}>
        <Icon as={AiFillClockCircle} boxSize={5} mr={1} />
        <Text>{duration}</Text>
      </Flex>
    </Flex>
<Link to={'/enroll_now'}>
        <Box as="button" p={2} color={'white'} fontWeight={'500'} fontSize={'13'} bg={'orange'} position={'bottom'} borderRadius={4}>Enroll now</Box>
</Link>
  </Box>
);


const NumberedBox = ({ number, text1, text2, icon }) => (
  <Flex direction={["column", "column","column"]} flexDirection={'column'} flexWrap={'wrap'}  justify={'center'}>
  <Box border="1px solid #ccc" p={4} m={4} flex="1" position="relative" borderRadius={15} >
    <Box p={4}>

    <Icon as={icon} boxSize={10} color="blue.500" position="absolute" top={2}  left={2} />
    </Box>
    <Flex direction={["column", "column", "row"]}  justify="space-between">
    <Text fontSize="5xl" fontWeight="bold" mb={4} m={4} > {number}</Text>
    <Box>
    <Text textAlign="left" fontSize={'xl'} fontWeight={'bold'}>{text1}</Text>
    <Text textAlign="left" fontSize={'sm'}>{text2}</Text>
    </Box>
    </Flex>
  </Box>
  </Flex>
);



const ImageWithTextOverlay = ({ imageUrl, heading, description }) => (
  <Box 
    bgImage={`url(${imageUrl})`}
    bgSize="cover"
    bgPosition="center"
    bgRepeat="no-repeat"
    position="relative"
    borderRadius="md"
    overflow="hidden"
    height="500px"
    width="100%"
    // mb={4}
  >
    <Box 
      position="absolute"
      bottom={0}
      left={0}
      right={0}
      bg="rgba(0, 0, 0, 0.6)"
      color="white"
      p={4}
      textAlign="center"
    >
      <Text fontSize="5xl" fontWeight="bold" mb={2}>{heading}</Text>
      <Text fontSize="md">{description}</Text>
    </Box>
  </Box>
);

const Course = () => (
  <Box>
    <Flex justify="center" align="center" direction="column" >
      <ImageWithTextOverlay
        imageUrl="https://www.devprojournal.com/wp-content/uploads/2022/07/low-code-no-code-development-1024x576.jpg"
        heading="OUR COURSES"
        // description="A stunning view of the sunset over the ocean."
      />
      {/* <ImageWithTextOverlay
        imageUrl="https://via.placeholder.com/800x400"
        heading="Snow-capped Mountains"
        description="Snowy peaks with clear blue skies in the background."
      /> */}
    </Flex>



  <Box p={["4", "6", "8"]} maxW="100%" mx="auto" color={'#003366'}>








   {/* <Heading as="h1" mb="4" fontSize={["xl", "2xl", "3xl"]} textAlign={'center'} color={'blue'} p={2}>Course details</Heading> */}
    
   <Box py={10} px={5}>
      <VStack spacing={4} divider={<StackDivider borderColor="gray.200" />}>
        {/* <Heading>Our Courses</Heading> */}
        <Text textAlign="center" maxW="800px"  fontWeight={'500'}>
          We offer a variety of courses in Full Stack MERN development and AI, designed to help you master the skills needed to succeed in the tech industry. Browse our course offerings below and enroll today!
        </Text>
      </VStack>
      {/* <SimpleGrid columns={[1, 2, 3]} spacing={8} mt={10}>
        {courses.map(course => (
          <CourseCard key={course.id} course={course} />
        ))}
      </SimpleGrid> */}
    </Box>
    
    {/* <Heading as="h2" size="md" mb="2" fontSize={["lg", "xl"]} textAlign={'center'}>Course Modules</Heading> */}



    <Flex direction={["column", "column", "row"]} wrap="wrap" justify="space-between">

    <Flex flexWrap="wrap" justify="space-between" p={4}>
      <CourseModule 
        imageName="https://eww-wp-new.s3.ap-south-1.amazonaws.com/wp-content/uploads/2020/05/10114231/guide-on-full-stack-development.jpg" 
        moduleName="Frontend Development Basics" 
        moduleDescription="Introduction to HTML, CSS, and JavaScript. Building simple web pages and understanding client-side development."
        duration="2 weeks"
      />
      <CourseModule 
        imageName="https://dailyskill.pk/assets/images/courses/1710756547.png" 
        moduleName="React Fundamentals" 
        moduleDescription="Understanding React components, state management, and creating interactive user interfaces."
        duration="2 weeks"
      />
      <CourseModule 
        imageName="https://jumpgrowth.com/wp-content/uploads/2019/09/advantages-of-nodejs.webp" 
        moduleName="Node.js and Express Basics" 
        moduleDescription="Building server-side applications with Node.js, using Express for routing and middleware."
        duration="4 weeks"
      />
      <CourseModule 
        imageName="https://www.sqlsplus.com/wp-content/uploads/2020/09/MongoDB-Database-sample.jpg" 
        moduleName="Database Design with MongoDB" 
        moduleDescription="Introduction to NoSQL databases, using MongoDB for data storage and retrieval."
        duration="2 weeks"
      />
      <CourseModule 
        imageName="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/168820317/original/dab53b6d05429da3bc9cf782c8f4795eaab5e898/create-restful-api-for-your-backend-server-in-nodejs.jpg" 
        moduleName="RESTful APIs with Express and MongoDB" 
        moduleDescription="Creating RESTful APIs using Node.js, Express, and integrating MongoDB for backend data storage."
        duration="2 weeks"
      />
      <CourseModule 
        imageName="https://statanalytica.com/blog/wp-content/uploads/2023/01/Full-Stack-Web-Development-Project-Ideas.webp" 
        moduleName="Full Stack Project Development" 
        moduleDescription="Building a complete full stack application from scratch, integrating frontend and backend technologies."
        duration="9 weeks"
      />
    </Flex>



   
   </Flex>




    <Heading textAlign={'center'}>How Can We Help You?</Heading>
   <Flex justify="space-between" p={4}>
      <NumberedBox 
        number={1}
        text1="Talk with Our Counsellor"
        text2="Discuss your career goals with our expert counselors and create a personalized learning plan."
        icon={FiPhoneCall}
      />
      <NumberedBox 
        number={2}
        text1="Get Enrolled in
Course"
        text2="Enroll in our comprehensive technology courses and gain industry-relevant skills."
        icon={PiStudentBold}
      />
      <NumberedBox 
        number={3}
        text1="Become Master Technology"
        text2="Upskill or reskill with our expert-led courses and become a master in technology."
        icon={FcManager}
      />
      <NumberedBox 
        number={4}
        text1="
Get Your Dream
Job"
        text2="Launch your dream career in technology with our career-focused courses and support."
        icon={HiMiniWallet}
      />
    </Flex>



   <Heading as="h2" size="md" mb="2" fontSize={["lg", "xl"]} textAlign={'center'}>Instructor Profiles</Heading>
    <SimpleGrid columns={[1, 1, 2]} spacing="4">
      <Box p="4" borderWidth="1px" borderRadius="lg">
        <Flex align="center">
          <Avatar src="/path/to/instructor1.jpg" size="xl" mr="4" />
          <Box>
            <Heading as="h3" size="md">Instructor Name</Heading>
            <Text fontSize="sm">Brief bio of the instructor highlighting their experience and expertise.</Text>
          </Box>
        </Flex>
      </Box>




      <Box p="4" borderWidth="1px" borderRadius="lg">
        <Flex align="center">
          <Avatar src="/path/to/instructor2.jpg" size="xl" mr="4" />
          <Box>
            <Heading as="h3" size="md">Instructor Name</Heading>
            <Text fontSize="sm">Brief bio of the instructor highlighting their experience and expertise.</Text>
          </Box>
        </Flex>
      </Box>
    </SimpleGrid>

    <Heading as="h2" size="md" mb="2" fontSize={["lg", "xl"]} textAlign={'center'}>Testimonials</Heading>
    <SimpleGrid columns={[1, 1, 2]} spacing="4">
      <Box p="4" borderWidth="1px" borderRadius="lg">
        <Text fontStyle="italic">"This training program was a game-changer for my career. Highly recommended!"</Text>
        <Text fontWeight="bold" mt="2">- Participant Name</Text>
      </Box>
      
      <Box p="4" borderWidth="1px" borderRadius="lg">
        <Text fontStyle="italic">"The instructors were knowledgeable and the curriculum was well-structured."</Text>
        <Text fontWeight="bold" mt="2">- Participant Name</Text>
      </Box>
    </SimpleGrid>

    {/* <Heading as="h2" size="md" mb="2" fontSize={["lg", "xl"]}>Course Schedule</Heading>
    <Box p="4" borderWidth="1px" borderRadius="lg" mb="4">
      <Text>Week 1-2: Introduction and Setup</Text>
      <Text>Week 3-4: MongoDB</Text>
      <Text>Week 5-6: Express.js</Text>
      <Text>Week 7-9: React.js</Text>
      <Text>Week 10-11: Node.js</Text>
      <Text>Week 12: Full-Stack Development and Capstone Project</Text>
    </Box> */}

    {/* <Heading as="h2" size="md" mb="2" fontSize={["lg", "xl"]}>Enrollment Information</Heading>
    <Box p="4" borderWidth="1px" borderRadius="lg" mb="4">
      <Text>To enroll in the course, please visit our enrollment page and fill out the registration form. For any queries, contact us at  <b>info@inspiringhopetech.com</b>.</Text>
    </Box> */}
  </Box>
  </Box>
);

export default Course;
