import { Box, Flex, Grid, GridItem, Icon, Image, Slider, SliderTrack, SliderFilledTrack,Heading, SliderThumb, Text, Button, HStack } from "@chakra-ui/react";
import { AiOutlinePlayCircle, AiOutlineClockCircle, AiOutlineFileDone, AiOutlineDollarCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

import { AiFillClockCircle } from 'react-icons/ai';
import { BiRupee } from "react-icons/bi";




const CourseModule = ({ imageName, moduleName, moduleDescription, duration }) => (
    <Box border="1px solid #ccc" p={4} mb={4} mr={1} boxShadow={'md'}  width={{ base: "100%", md: "50%", lg: "33%" }}>
      <Flex direction="column" align="center" justify="center">
        <Box>
  
        <Image src={imageName} alt={moduleName}  mb={4} />
        </Box>
        <Heading as="h3" size="md" textAlign="center" mb={2} color={'navy'}>{moduleName}</Heading>
        <Text textAlign="center" mb={2}>{moduleDescription}</Text>
        <Flex align="center" justify="center"  mb={3}>
          <Icon as={AiFillClockCircle} boxSize={5} mr={1} />
          <Text>{duration}</Text>
        </Flex>
      </Flex>
  <Link to={'/enroll_now'}>
          <Box as="button" p={2} color={'white'} fontWeight={'500'} fontSize={'13'} bg={'orange'} position={'bottom'} borderRadius={4}>Enroll now</Box>
  </Link>
    </Box>
  );
  


const CourseDetailsComponent = () => {
  return (
    <Box p={4} borderWidth="1px" borderRadius="md">
      {/* Course Title */}
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        Full Stack Development & AI Course
      </Text>

      {/* Course Features Grid */}
      <Grid templateColumns="repeat(2, 1fr)" gap={4} mb={4}>
        {/* Course Fee */}
        <GridItem colSpan={1}>
          <Flex alignItems="center">
            <Icon as={AiOutlineDollarCircle} boxSize={5} color="blue.500" mr={2} />
            <Text fontWeight="bold">Course Fee</Text>
          </Flex>
          <HStack>
            <BiRupee/>
          <Text>18000</Text>
          </HStack>
        </GridItem>

        {/* Course Duration */}
        <GridItem colSpan={1}>
          <Flex alignItems="center">
            <Icon as={AiOutlineClockCircle} boxSize={5} color="blue.500" mr={2} />
            <Text fontWeight="bold">Duration</Text>
          </Flex>
          <Text>6 months</Text>
        </GridItem>

        {/* Demo Video */}
        <GridItem colSpan={2}>
          <Flex alignItems="center">
            <Icon as={AiOutlinePlayCircle} boxSize={5} color="blue.500" mr={2} />
            <Text fontWeight="bold">Demo Video</Text>
          </Flex>
          <Box mt={2} mb={4}>
            {/* Replace with your demo video thumbnail or embed */}
            <Image src="/demo-video-thumbnail.jpg" alt="Demo Video Thumbnail" borderRadius="md" />
          </Box>
        </GridItem>

        {/* Recorded Sessions */}
        <GridItem colSpan={2}>
          <Flex alignItems="center">
            <Icon as={AiOutlineFileDone} boxSize={5} color="blue.500" mr={2} />
            <Text fontWeight="bold">Recorded Sessions</Text>
          </Flex>
          <Text>Access to all recorded sessions</Text>
        </GridItem>
      </Grid>

      {/* Choose Your Plan Button */}
      <Flex direction={["column", "column", "row"]} wrap="wrap" justify="space-between">
      <Button colorScheme="blue" mb={4}>
        Choose Your Plan
      </Button>

<Flex flexWrap="wrap" justify="space-between" p={4}>
  <CourseModule 
    imageName="https://eww-wp-new.s3.ap-south-1.amazonaws.com/wp-content/uploads/2020/05/10114231/guide-on-full-stack-development.jpg" 
    moduleName="Frontend Development Basics" 
    moduleDescription="Introduction to HTML, CSS, and JavaScript. Building simple web pages and understanding client-side development."
    duration="2 weeks"
  />
  <CourseModule 
    imageName="https://dailyskill.pk/assets/images/courses/1710756547.png" 
    moduleName="React Fundamentals" 
    moduleDescription="Understanding React components, state management, and creating interactive user interfaces."
    duration="2 weeks"
  />
  <CourseModule 
    imageName="https://jumpgrowth.com/wp-content/uploads/2019/09/advantages-of-nodejs.webp" 
    moduleName="Node.js and Express Basics" 
    moduleDescription="Building server-side applications with Node.js, using Express for routing and middleware."
    duration="4 weeks"
  />
  <CourseModule 
    imageName="https://www.sqlsplus.com/wp-content/uploads/2020/09/MongoDB-Database-sample.jpg" 
    moduleName="Database Design with MongoDB" 
    moduleDescription="Introduction to NoSQL databases, using MongoDB for data storage and retrieval."
    duration="2 weeks"
  />
  <CourseModule 
    imageName="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/168820317/original/dab53b6d05429da3bc9cf782c8f4795eaab5e898/create-restful-api-for-your-backend-server-in-nodejs.jpg" 
    moduleName="RESTful APIs with Express and MongoDB" 
    moduleDescription="Creating RESTful APIs using Node.js, Express, and integrating MongoDB for backend data storage."
    duration="2 weeks"
  />
  <CourseModule 
    imageName="https://statanalytica.com/blog/wp-content/uploads/2023/01/Full-Stack-Web-Development-Project-Ideas.webp" 
    moduleName="Full Stack Project Development" 
    moduleDescription="Building a complete full stack application from scratch, integrating frontend and backend technologies."
    duration="9 weeks"
  />
</Flex>




</Flex>
      {/* How We Work */}
      {/* <Box>
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          How We Work
        </Text>
        <Slider aria-label="process-slider" defaultValue={0}>
          <SliderTrack bg="gray.100">
            <SliderFilledTrack bg="blue.500" />
          </SliderTrack>
          <SliderThumb boxSize={6} bg="blue.500" />
        </Slider>
        <Flex justify="space-between" mt={2}>
          <Text>Step 1</Text>
          <Text>Step 2</Text>
          <Text>Step 3</Text>
          <Text>Step 4</Text> 
        </Flex>
      </Box> */}
    </Box>
  );
};

export default CourseDetailsComponent;
