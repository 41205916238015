import { Box, Flex, Heading, Text, Img, HStack } from '@chakra-ui/react'
import React from 'react'
import { FaHome } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import logo from './assets/logo.png'
import { MdOutlineEmail, MdAccessTime } from "react-icons/md";
import { BiSolidPhoneCall } from "react-icons/bi";

const OverlayComponent = () => {
  return (
    <Box
      position="relative"
      w="100%"
      h="600px" // Adjust height as needed
      overflow="hidden"
    >
      <Img
        src="https://stackpos.in/assets/images/banner-(2).jpg"
        w="100%"
        h="100%"
        objectFit="cover"
        alt="Background Image"
      />
      <Box
        position="absolute"
        top="0%"
        left="50%"
        transform="translate(-50%, 0)"
        bg="rgba(0, 0, 0, 0.5)"
        color="white"
        p={9}
        h={'100vh'}
        borderRadius="md"
        textAlign="left"
        w={{ base: '100%', md: '100%' }}
      >
         {/* <Img src={logo} w={{ base: 'auto', md: 200 }} h={{ base: 'auto', md: 100}} /> */}
        <Heading fontSize={{ base: '20px', md: '28px' }} mb={4} color={'blue.400'}>Poorvanchal Systems</Heading>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          gap={4}
        >
          <Box w={{ base: '100%', md: '33%' }} mb={{ base: 4, md: 0 }}>
            <Heading fontSize="22px" mb={2} color={'blue.400'}><FaHome/></Heading>
            <Text mb={5} fontWeight={'600'}>
            1st Floor, Hemganga Complex,<br />
            Imirti Road, <br />
            Next to Mission Hospital,<br />
            Robertsganj-231216,<br />
            Sonbhadra,<br />
            Uttar Pradesh (U.P)
            
            </Text>
            {/* <Heading fontSize="22px" mb={2} color={'blue.400'}>Kenya Address:</Heading>
            <Text fontWeight={'600'}>
            WOODVALE GROVE<br />
            MPAKA HOUSE<br />
            WESTLANDS<br />
            NAIROBI,KENYA<br />
            P.O BOX 66602<br />
            TELEPHONE: +254715175279
            </Text> */}
          </Box>
          <Box w={{ base: '100%', md: '33%' }} mb={{ base: 4, md: 0 }}>
            <HStack color={'blue.400'} mb={2}>
           
            <Heading fontSize="22px"  color={'blue.400'}> <BiSolidPhoneCall size={30}/> </Heading>
            </HStack>
            <Text fontSize={'20'} fontWeight={'600'}>
          +91 9583185191<br />
              +91 7033289785
            </Text>

          </Box>
          <Box w={{ base: '100%', md: '33%' }} color={'blue.400'}>
            {/* <Heading fontSize="22px" mb={2} color={'blue.400'}>Email</Heading> */}
            <MdOutlineEmail size={30} color={'blue.400'}/>
            {/* <HStack spacing={2} justifyContent='center'> */}
            <Box>

            <Text color={'white'}  fontSize={'20'} fontWeight={'600'}>poorvanchalsystems@gmail.com</Text>
            <Text color={'white'} fontSize={'20'} fontWeight={'600'}>
            {/* info@stackpos.in<br /> */}
            info@inspiringhopetech.com
            </Text>
            </Box>
          {/* </HStack> */}
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default OverlayComponent;
