import { Flex, Heading, Img, Text, Box, HStack } from '@chakra-ui/react'
import React from 'react'
import logo from './assets/logo.png'
import { FaHome } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { FaGooglePlusSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <Flex
      w='100%'
    
      bg='#0072bb'
      color='white'
      wrap='wrap'
      direction={{ base: 'column', md: 'row' }}
      p={8}
      justifyContent={'center'}
      alignItems={'center'}
    //   gap={1}
    >
      <Box w={{ base: '100%', md: '33%' }} textAlign={{ base: 'center', md: 'left' }} mb={{ base: 4, md: 0 }}>
        <Heading mb={2}>
          <Img src={logo} w={{ base: 'auto', md: 199 }} h={{ base: 'auto', md: 100 }} />
        </Heading>
       
          <FaHome size={20}/>
       
        <Text fontSize={'14'} mb={2} fontWeight={'500'}>
        1st Floor, Hemganga Complex, Imirti Road, Next to Mission Hospital, Robertsganj, Sonbhadra, Uttar Pradesh (U.P)
        </Text>
        <HStack>
<IoCall size={15}/>
        <Text fontSize={'15'} fontWeight={'600'}>
          +91 9583185191<br />
              +91 7033289785
            </Text>
            </HStack>
      </Box>
      <Box w={{ base: '100%', md: '33%' }}  h={190} textAlign={{ base: 'center', md: 'left' }} mb={{ base: 4, md: 0 }}>
        <Heading mb={2} fontSize={'20'} >Company</Heading>
        <Link to={'/about'}>
        <Text fontSize={'14'} fontWeight={'500'}>About US</Text>
        </Link>
<Link to={'/contact'}>
        <Text fontSize={'14'} fontWeight={'500'}>Contact US</Text>
</Link>
{/* <Link > */}
        {/* <Text fontSize={'14'}>FAQs</Text> */}
{/* </Link> */}
        {/* <Text fontSize={'14'}>Portfolio</Text> */}
      </Box>
      <Box w={{ base: '100%', md: '33%' }} textAlign={{ base: 'center', md: 'left' }} mb={{ base: 4, md: 0 }}>
        <Heading mb={2} fontSize={'20'} >Other Info</Heading>
        <Text fontSize={'15'} mb={3} fontWeight={'500'}>Our visions to future and technology helps our customer is wide range and options.
        With highly rich and robust background, we keep maintaining sound profile employment, who can be smartest in the way of interaction with real world. Still guides me on selecting right everytime!</Text>
       <HStack spacing={3} justifyContent={'center'}>
        <Link>
         <FaGooglePlusSquare size={30}/>
        </Link>
        <Link>
        <FaLinkedin size={30}/>
        </Link>
        <Link>
       <FaSquareInstagram size={30}/>
        </Link>
        </HStack>
        {/* <Text fontSize={'15'}>Web Development</Text>
        <Text fontSize={'15'}>Web Designing</Text> */}
      </Box>
      {/* <Box w={{ base: '100%', md: '25%' }} textAlign={{ base: 'center', md: 'left' }}>
        <Heading mb={2} fontSize={'20'} >India Address</Heading>
        <Text >
          Rajashree Complex<br/>
          First Floor<br/>
          Parvathi Nagar<br/>
          Near DDCC Bank<br/>
          Krishnagiri, Hosur<br/>
          Tamilnadu, India-635 109
        </Text>
      </Box> */}
    </Flex>
  )
}

export default Footer
