import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Login';
import Home from './Home2';
import University from './University';
import User from './User';
import Users from './Users';
import Enquiry from './Enquiry';
import Profile from './Profile';
import { ChakraProvider } from '@chakra-ui/react';
import Setting from './Setting';
import { useSelector } from 'react-redux';

import App2 from '../App2';
import Home2 from './Home2';

const Main = () => {
  const {isAuthenticated,data}=useSelector((state)=>state.authReducer)
  return (
    <ChakraProvider>
   
      <Routes>
        {/* <Route path="/" element={<App2 />} />
        <Route path="/login_admin" element={<Login />} />  */}


        {isAuthenticated&&<>
        {/* <Navbar/> */}
        <Route path="/dashboard" element={<Home2 />} />
        {/* <Route path="/manage_course" element={<University />} /> */}
        {/* <Route path="/enquiry" element={<Enquiry />} /> */}
        <Route path="/manage_users" element={<Users />} /> 
        <Route path="/manage_student" element={<User />} />
        {/* <Route path="/profile" element={<Profile />} />
        <Route path="/setting" element={<Setting/>} /> */}
        </>
}
      </Routes>
    {/* </Router> */}
    </ChakraProvider>
  );
};

export default Main;
