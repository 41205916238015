import React from 'react'
import { Box, Flex, Heading, Img, Text, Grid, GridItem } from '@chakra-ui/react'

const services = [
  {
    src: 'https://www.rishabhsoft.com/wp-content/uploads/2022/02/MEAN-vs-MERN-Banner-Image.jpg',
    description: 'MERN FULL STACK DEVELOPER',
    about: 'Develop and maintain web applications using the MERN Stack (MongoDB, Express, React, Node.js). Join our dynamic team to gain experience and contribute to our projects..'
  },
  {
    src: 'https://www.zibtek.com/blog/content/images/2020/08/eCommerce-Development-Services.png',
    description: 'ECOMMERCE SERVICES',
    about: "Want to start an eCommerce store? Witness an expanded growth of your business with our customized eCommerce services . Our full-suite of eCommerce development services include:"
  },
  {
    src: 'https://www.gracethemesdemo.com/documentation/online-consulting/images/slider.jpg',
    description: 'BUSINESS CONSULTATION SERVICES',
    about: "With our business consultation services we help you adapt and thrive in the dynamic markets of today. We work with you all through the way to navigate you overcome complex business challenges."
  },
  {
    src: 'https://stackpos.in/assets/images/services(1).jpg',
    description: 'WEB DEVELOPMENT',
    about: "By using cutting-edge technologies, our in-house team of developers delivers intuitive and highly agile websites. By keeping your vision in focus we bring out a digital transformation for your business that helps you further broaden your"
  },
  {
    src: 'https://www.analyticssteps.com/backend/media/thumbnail/4530462/5951113_1605265443_AI%20(7).jpg',
    description: 'AI & MACHINE LEARNING ',
    about: "Artificial Intelligence (AI): Simulates human intelligence in machines to perform tasks like understanding language, recognizing patterns, and making decisions.   Machine Learning (ML): A subset of AI, focuses on training algorithms with data to learn patterns and make autonomous decisions, improving over time with experience."
  },
  {
    src: 'https://stackpos.in/assets/images/services(5).jpg',
    description: 'MOBILE APPLICATIONS',
    about: "With an industry-best approach to mobile application development services , we bring together the best development and design practices to provide a full cycle of mobile app development for both, Android and IOS devices."
  }
];



const ImageWithTextOverlay = ({ imageUrl, heading, description }) => (
  <Box 
    bgImage={`url(${imageUrl})`}
    bgSize="cover"
    bgPosition="center"
    bgRepeat="no-repeat"
    position="relative"
    borderRadius="md"
    overflow="hidden"
    height="500px"
    width="100%"
    // mb={4}
  >
    <Box 
      position="absolute"
      bottom={0}
      left={0}
      right={0}
      bg="rgba(0, 0, 0, 0.6)"
      color="white"
      p={4}
      textAlign="center"
    >
      <Text fontSize="5xl" fontWeight="bold" mb={2}>{heading}</Text>
      <Text fontSize="md">{description}</Text>
    </Box>
  </Box>)

const Service = () => {
  return (
    <Box>
<Flex justify="center" align="center" direction="column" >
      <ImageWithTextOverlay
        imageUrl="https://www.refreshkid.com/uploads/blogs/202310182253016828727_coding_essentials_.jpg"
        heading="OUR SERVICES"
        // description="A stunning view of the sunset over the ocean."
      />
      {/* <ImageWithTextOverlay
        imageUrl="https://via.placeholder.com/800x400"
        heading="Snow-capped Mountains"
        description="Snowy peaks with clear blue skies in the background."
      /> */}
    </Flex>


    <Flex
      w='100%'
      bg='ghost'
      color='black'
      wrap='wrap'
      direction={'column'}
      p={4}
      justifyContent={'center'}
      alignItems={'center'}
    >
      {/* <Heading
        // fontSize={{ base: '20px', md: '25px' }}
        color={'#4169e1'}
        textAlign={'center'}
        mb={4}
      >
        OUR SERVICES
      </Heading> */}
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
        gap={6}
        w='100%'
        maxW='1200px'
      >
        {services.map((service, index) => (
          <GridItem key={index} textAlign='left'>
            <Box boxShadow={'sm'} p={6} letterSpacing={1} >
              <Img src={service.src} alt={`Service ${index + 1}`} mb={4} />
              <Text fontWeight={'700'} fontSize={{ base: '15px', md: '23px' }} color={'blue.400'}>{service.description}</Text>
              <Text fontWeight={'400'} fontSize={{ base: '15px', md: '16px' }} color={'gray'}>{service.about}</Text>
            </Box>
          </GridItem>
        ))}
      </Grid>
    </Flex>
    </Box>
  )
}

export default Service
