import React from 'react';
import { Box, Heading, Text, VStack, Image, SimpleGrid, StackDivider,Icon } from '@chakra-ui/react';
import { FaTrophy, FaAward, FaMedal } from 'react-icons/fa';
import { FaBullseye, FaGlobe, } from 'react-icons/fa';

const AboutPage = () => {
  return (
    <Box>
      {/* Company History Section */}
      <Box py={10} px={5}>
        <VStack spacing={4} divider={<StackDivider borderColor="gray.200" />}>
          <Heading color={'#4169e1'}>Our History</Heading>
          <Text textAlign="center" maxW="700px" fontWeight={'500'}>
            Founded in 2023, our company has quickly grown into a leading provider of Full Stack MERN stack and AI courses. Our mission is to empower individuals with the skills needed to excel in the tech industry. We have a dedicated team of experienced instructors who are passionate about teaching and helping students achieve their career goals.
          </Text>
        </VStack>
      </Box>

      {/* Mission and Vision Section */}
      <Box bg="blue.100" py={10} px={5} mb={10}>
        <SimpleGrid columns={[1, 2]} spacing={10} maxW="1100px" mx="auto">
          <VStack spacing={4}>
            <Icon as={FaBullseye} boxSize={10} color="#4169e1" />
            <Heading color={'#4169e1'}>Our Mission</Heading>
            <Text fontWeight={'500'} textAlign={'center'}>
              Our mission is to provide high-quality education and training in Full Stack MERN stack and AI, enabling our students to become proficient developers and data scientists. We aim to bridge the gap between education and industry by offering practical, hands-on experience and job placement services.
            </Text>
          </VStack>
          <VStack spacing={4}>
            <Icon as={FaGlobe} boxSize={10} color="#4169e1" />
            <Heading color={'#4169e1'}>Our Vision</Heading>
            <Text fontWeight={'500'} textAlign={'center'}>
              Our vision is to be a global leader in tech education, recognized for our innovative teaching methods and successful student outcomes. We strive to create a learning environment that fosters growth, creativity, and excellence, preparing our students to meet the demands of the ever-evolving tech industry.
            </Text>
          </VStack>
        </SimpleGrid>
      </Box>

      {/* Key Team Members Section */}
      {/* <Box py={10} px={5}>
        <Heading textAlign="center" color={'#4169e1'} mb={6}>Meet Our Team</Heading>
        <SimpleGrid columns={[1, 2, 3]} spacing={8} maxW="1200px" mx="auto">
          <VStack spacing={4}>
            <Image borderRadius="full" boxSize="150px" src="https://via.placeholder.com/150" alt="Team Member 1" />
            <Heading size="md">John Doe</Heading>
            <Text>CEO & Co-Founder</Text>
            <Text textAlign="center">John has over 15 years of experience in software development and has a passion for teaching and mentoring aspiring developers.</Text>
          </VStack>
          <VStack spacing={4}>
            <Image borderRadius="full" boxSize="150px" src="https://via.placeholder.com/150" alt="Team Member 2" />
            <Heading size="md">Jane Smith</Heading>
            <Text>CTO & Co-Founder</Text>
            <Text textAlign="center">Jane is an expert in AI and machine learning, with extensive experience in developing cutting-edge AI applications.</Text>
          </VStack>
          <VStack spacing={4}>
            <Image borderRadius="full" boxSize="150px" src="https://via.placeholder.com/150" alt="Team Member 3" />
            <Heading size="md">Michael Johnson</Heading>
            <Text>Lead Instructor</Text>
            <Text textAlign="center">Michael has a strong background in full stack development and has been teaching web development courses for over 10 years.</Text>
          </VStack>
        </SimpleGrid>
      </Box> */}

      {/* Achievements Section */}
      <Box bg="gray.100" py={10} px={5}>
        <Heading textAlign="center" color={'#4169e1'} mb={6}>Our Achievements</Heading>
        <VStack spacing={4} maxW="999px" mx="auto">
          <Text textAlign={'center'} fontWeight={'500'}>
            As a new startup, we are proud of our early accomplishments and the rapid growth we have achieved. Our dedication to providing top-notch education and our students' success is already being recognized.
          </Text>
          <SimpleGrid columns={[1, 3]} spacing={10} mt={4}>
            <VStack align="center">
              <FaTrophy size="40px" color="#4169e1" />
              <Text fontWeight="700" fontSize={'20'}>2023 Startup Launch</Text>
              <Text textAlign="center" fontWeight={'500'}>Successfully launched our first courses and enrolled over 500 students within the first few months.</Text>
            </VStack>
            <VStack align="center">
              <FaAward size="40px" color="#4169e1" />
              <Text fontWeight="700" fontSize={'20'}>Innovative Curriculum</Text>
              <Text textAlign="center" fontWeight={'500'}>Developed a curriculum that blends theoretical knowledge with practical skills, receiving positive feedback from students and industry experts.</Text>
            </VStack>
            <VStack align="center">
              <FaMedal size="40px" color="#4169e1" />
              <Text fontWeight="700" fontSize={'20'}>Growing Community</Text>
              <Text textAlign="center" fontWeight={'500'}>Built a strong community of learners and professionals who support and learn from each other.</Text>
            </VStack>
          </SimpleGrid>
        </VStack>
      </Box>
    </Box>
  );
};

export default AboutPage;
