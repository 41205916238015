import { Box, Flex, HStack, Text } from '@chakra-ui/react'
import React from 'react'
import { FaFacebook } from "react-icons/fa";
import { FaGooglePlus } from "react-icons/fa6";
import { AiFillTwitterCircle } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { MdOutlineEmail, MdAccessTime } from "react-icons/md";
import { RiLoginCircleLine, RiPhoneLine } from "react-icons/ri";
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <Flex w='100%' bg='#0072bb ' color='white' wrap='wrap'>
      <Flex p={3} ml={{ base: 2, md: 10 }} justifyContent={{ base: 'center', md: 'flex-start' }} w={{ base: '100%', md: 'auto' }}>
        <HStack spacing={3}>
          <Link>
          <FaFacebook size={20} />
          </Link>
          <Link>
          <FaGooglePlus size={20} />
          </Link>
          <Link>
          <AiFillTwitterCircle size={20} />
          </Link>
          <Link>
          <AiFillLinkedin size={20} />
          </Link>
          {/* <HStack spacing={5}> */}
          <HStack spacing={3}  alignItems='center'>
            <MdAccessTime size={18} />
            <Text mr={4}>Mon - Sat, 10:00 AM - 07:00 PM</Text>
            <RiPhoneLine size={18} />
            <Text>+91 9583185191, 7033289785</Text>
          </HStack>
          {/* </HStack> */}
        </HStack>
      </Flex>

      <Flex w={{ base: '100%', md: 'auto' }} justifyContent={{ base: 'center', md: 'flex-end' }} flexGrow={1}>
        <HStack spacing={4} mr={{ base: 0, md: 4 }} flexWrap='wrap' justifyContent='center'>
            <Link>
          <HStack spacing={2} mr={{ base: 0, md: 8 }} justifyContent='center'>
            <MdOutlineEmail size={18} />
            <Text>poorvanchalsystems@gmail.com</Text>
          </HStack>
            </Link>
            <Link>
          <HStack spacing={2} mr={{ base: 0, md: 8 }} justifyContent='center'>
            <MdOutlineEmail size={18} />
            <Text>info@inspiringhopetech.com</Text>
          </HStack>
            </Link>
        </HStack>
      </Flex>
    </Flex>
  )
}

export default Header;
