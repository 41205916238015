import { Box, Heading, Text, Flex, List, ListItem, ListIcon, Grid, GridItem, Divider ,HStack,Link} from "@chakra-ui/react";
import { CheckCircleIcon, EmailIcon, PhoneIcon, ChatIcon, QuestionIcon } from "@chakra-ui/icons";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { AspectRatio } from '@chakra-ui/react'

const ImageWithTextOverlay = ({ imageUrl, heading, description }) => (
  <Box 
    bgImage={`url(${imageUrl})`}
    bgSize="cover"
    bgPosition="center"
    bgRepeat="no-repeat"
    position="relative"
    borderRadius="md"
    overflow="hidden"
    height="500px"
    width="100%"
    // mb={4}
  >
    <Box 
      position="absolute"
      bottom={0}
      left={0}
      right={0}
      bg="rgba(0, 0, 0, 0.6)"
      color="white"
      p={4}
      textAlign="center"
    >
      <Text fontSize="5xl" fontWeight="bold" mb={2}>{heading}</Text>
      <Text fontSize="md">{description}</Text>
    </Box>
  </Box>)

const SupportMenu = () => (
  <Box>
    <Flex justify="center" align="center" direction="column" >
      <ImageWithTextOverlay
        imageUrl="https://recipe.de/wp-content/uploads/AdobeStock_170263847_Support-scaled.jpg"
        heading="OUR SUPPORT"
        // description="A stunning view of the sunset over the ocean."
      />
      {/* <ImageWithTextOverlay
        imageUrl="https://via.placeholder.com/800x400"
        heading="Snow-capped Mountains"
        description="Snowy peaks with clear blue skies in the background."
      /> */}
    </Flex>

  <Box p={["4", "6", "8"]} maxW="100%" mx="auto" color={'#003366'}>
   {/* <Heading as="h1" mb="4" fontSize={["xl", "2xl", "3xl"]} textAlign={'center'}  color={'blue'} p={2}>Support</Heading> */}
    
    <Flex direction={["column", "column", "row"]} justify="space-between" alignItems="start">
      <Box mb="4" width={["100%", "100%", "48%"]}>
        <Heading as="h2" size="md" mb="2" fontSize={["lg", "2xl"]} color={'#4169e1'}>Contact Info</Heading>
        <List spacing={2}>
          <ListItem>
            <ListIcon as={EmailIcon} color="blue.500" />
            <Text>Email: inspiringhopetech@gmail.com</Text>
          </ListItem>
          <ListItem>
            <ListIcon as={PhoneIcon} color="green.500" />
            <Text>Phone: +91 9583185191</Text>
          </ListItem>
          <ListItem>
            <ListIcon as={ChatIcon} color="purple.500"  />
            <Text mb={5}>Live Chat: Available during support hours</Text>
          </ListItem>
          
        </List>
        <HStack spacing="4" mb="4">
      <Link href="https://facebook.com" isExternal><FaFacebookSquare size={40}/></Link>
      <Link href="https://twitter.com" isExternal><FaSquareTwitter size={40}/></Link>
      <Link href="https://linkedin.com" isExternal><FaLinkedin size={40}/></Link>
    </HStack>
    
      </Box>

      <Box mb="4" width={["100%", "100%", "48%"]}>
        <Heading as="h2" size="md" mb="2" fontSize={["lg", "2xl"]} color={'#4169e1'}>FAQs</Heading>
        <List spacing={2}>
          <ListItem>
            <ListIcon as={QuestionIcon} color="gray.500" />
            <Text><strong>Q:</strong> What are the prerequisites for this course?</Text>
            <Text><strong>A:</strong> Basic knowledge of HTML, CSS, and JavaScript is recommended.</Text>
          </ListItem>
          <ListItem>
            <ListIcon as={QuestionIcon} color="gray.500" />
            <Text><strong>Q:</strong> How can I enroll in the course?</Text>
            <Text><strong>A:</strong> Visit our enrollment page and fill out the registration form.</Text>
          </ListItem>
          <ListItem>
            <ListIcon as={QuestionIcon} color="gray.500" />
            <Text><strong>Q:</strong> Is financial aid available?</Text>
            <Text><strong>A:</strong> Yes, we offer scholarships based on merit and need.</Text>
          </ListItem>
          <ListItem>
            <ListIcon as={QuestionIcon} color="gray.500" />
          <Text mb="4" >
      <strong>Q:</strong> What is the duration of the course? <br/>
      <strong>A:</strong> The course lasts for 12 weeks.
    </Text>
    </ListItem>
    <ListItem>
            <ListIcon as={QuestionIcon} color="gray.500" />
        
    <Text mb="4" >
      <strong>Q:</strong> Do I get a certificate upon completion? <br/>
      <strong>A:</strong> Yes, you will receive a certification recognized by industry leaders.
    </Text>
          </ListItem>
        </List>
      </Box>
    </Flex>

    <Divider my="6" />

    <Box>
      <Heading as="h2" size="md" mb="2" fontSize={["lg", "xl"]} color={'#4169e1'}>Support Hours</Heading>
      <Text mb="4" fontSize={["sm", "md", "lg"]}>
        Our support team is available from Monday to Saturday, 9:00 AM to 7:00 PM (GMT+1). For urgent inquiries outside these hours, please leave a message and we will get back to you as soon as possible.
      </Text>
    </Box>
  </Box>
  </Box>
);

export default SupportMenu;
