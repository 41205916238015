import axios from "axios";
import { LOADUSER_REQUEST, LOGIN_REQUEST, LOGIN_REQUEST_FAIL, LOGIN_REQUEST_SUCCESS,LOADUSER_REQUEST_SUCCESS,LOADUSER_REQUEST_FAIL, LOGOUTUSER_REQUEST, LOGOUTUSER_REQUEST_SUCCESS, LOGOUTUSER_REQUEST_FAIL } from "./actionType";


const backend_url=process.env.REACT_APP_BACKEND_URL;


//export const LoginAction = (email,password) =>async(dispatch) => {
 
// try{  
//     dispatch({ type:LOGIN_REQUEST });
//const config={headers:{'Content_Type':'application/json'}}

//       const {data}= await axios.post(`/api/adminlogin`,{email,password},config)

      


//dispatch({ type:LOGIN_REQUEST_SUCCESS, payload:data });


   
   
  
//}
//    catch(error) {
//      dispatch({ type:LOGIN_REQUEST_FAIL });
//    };
//};


export const LoaduserAction=()=>async(dispatch)=>{
    const config={headers:{'Content_Type':'application/json',  withCredentials: true }
  
}

try {
    dispatch({type:LOADUSER_REQUEST})



    const {data}=await axios.get(`/api/loaduser`,config,{withCredentials:'include'})

dispatch({ type:LOADUSER_REQUEST_SUCCESS, payload:data });

} catch (error) {
    dispatch({type:LOADUSER_REQUEST_FAIL,})
}


}



export const LogoutuserAction=()=>async(dispatch)=>{
    const config={headers:{'Content_Type':'application/json'}}


    try {
        dispatch({type:LOGOUTUSER_REQUEST})
    
    
        
        await axios.post(`/api/logout`,config,{withCredentials:true})
    
    dispatch({ type:LOGOUTUSER_REQUEST_SUCCESS});

    } catch (error) {
        dispatch({type:LOGOUTUSER_REQUEST_FAIL,})
    }
    
    
    }

//export const fetchEvent=()=>async(dispatch)=>{

//try {
//  dispatch({type:EVENT_REQUEST})

//  const { data} = await axios.get(`${BACKEND_URL}/api/v1/find_event`);
//dispatch({type:EVENT_REQUEST_SUCCESS,payload:data})

//} catch (error) {
  
//}



//}
