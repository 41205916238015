import React, { useState } from "react";
import { Box, Text, HStack, FormLabel, Input, Button, Textarea, useBreakpointValue, Container, Flex } from "@chakra-ui/react";
import axios from 'axios';

const backend_url = process.env.REACT_APP_BACKEND_URL;

const ApplyForm = () => {
  const containerMaxW = useBreakpointValue({ base: '100%', md: '80%' }); // Adjusted max-width for responsiveness
  const formWidth = useBreakpointValue({ base: '100%', md: '50%' }); // Form width adjusted for responsiveness

  const [formData, setFormData] = useState({
    candidateName: '',
    highestEducation: '',
    careerObjective: '',
    // email: '',
    mobileNumber: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isEmptyField = Object.values(formData).some(value => value === '');
    if (isEmptyField) {
      alert('Please fill in all fields');
      return;
    }

    try {
      const response = await axios.post(`${backend_url}/api/quick_form-submit`, formData);
      console.log('Form submitted successfully:', response.data);
      alert('Form submitted successfully');
      setFormData({
        candidateName: '',
        highestEducation: '',
        mobileNumber: '',
        // email: '',
        careerObjective: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred while submitting the form. Please try again later.');
    }
  };

  const handleClear = () => {
    setFormData({
      candidateName: '',
      highestEducation: '',
      // email: '',
      careerObjective: '',
      mobileNumber: '',
    });
  };

  return (
    <Container maxW={containerMaxW} py={4}>
      <Flex  spacing={10} justifyContent={'center'} direction={'row'} wrap={'wrap'} >


         {/* Right Box (Note: You can add content here as per your design) */}
         <Box  p={4} borderRadius={4} boxShadow="md" width={formWidth}>
          <Text fontWeight={'bold'} fontSize={'5xl'}  color={'orange'} >MERN Stack Developer</Text>
          <Text fontSize={'2xl'} fontWeight={'bold'}>Course with Placement Support</Text>
          <Text fontSize={'md'} mt={5}>Make your IT career dreams come true with our MERN Stack Developer Course! Train with top experts, build in-demand skills, and land your dream job with dedicated placement support and opportunities from a pool of  companies.</Text>
        </Box>

        {/* Left Box */}
        <Box bg={'white'} p={4} borderRadius={4} boxShadow="md" width={formWidth}>

          {/* <Text textAlign={'center'} bg={'blue.600'} p={2} borderRadius={2} color={'white'} fontWeight={'bold'}>POORVANCHAL SYSTEMS</Text> */}

          <Box textAlign={'center'} bg={'white'} p={4} mt={4} borderRadius={4} borderWidth={'1px'} borderColor={'gray.200'}>

            <FormLabel fontSize={'md'}>Candidate Name:</FormLabel>
            <Input mb={2} borderColor={'gray'} size={'md'}  borderRadius={15} type='text' name='candidateName' value={formData.candidateName} onChange={handleChange} />

            {/* <FormLabel fontSize={'md'}>Email:</FormLabel> */}
            {/* <Input mb={2} borderColor={'gray'} size={'sm'} type='email' name='email' value={formData.email} onChange={handleChange} /> */}

            <FormLabel fontSize={'md'}>Highest Education Qualification:</FormLabel>
            <Input mb={2} borderColor={'gray'} size={'sm'} type='text' name='highestEducation' value={formData.highestEducation} onChange={handleChange} />

            <FormLabel fontSize={'md'}>Mobile Number:</FormLabel>
            <Input mb={2} borderColor={'gray'} size={'sm'} type='number' pattern="\d{10}" name='mobileNumber' value={formData.mobileNumber} onChange={handleChange} />

            <Textarea borderColor={'gray'} size={'sm'} name='careerObjective' fontSize={'13'} value={formData.careerObjective} placeholder='In one line, write your career objective' onChange={handleChange} />
          </Box>
      <HStack justifyContent={'center'} mt={4}>
        <Button colorScheme='teal' onClick={handleClear} boxShadow={'md'} borderRadius={'2'}>Clear</Button>
        <Button colorScheme='blue' onClick={handleSubmit} boxShadow={'md'} borderRadius={'2'}>Submit</Button>
      </HStack>
        </Box>

       

      </Flex>

    </Container>
  );
};

export default ApplyForm;
