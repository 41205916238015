import { Table, Thead, Tbody, Tr, Th, Td, Box,HStack,Img,Text, Button } from '@chakra-ui/react';
import { useState,useEffect } from 'react';
import {DeleteIcon } from '@chakra-ui/icons'
import axios from 'axios';
import QuickUser from './QuickUser';
const backend_url=process.env.REACT_APP_BACKEND_URL;


const User = () => {
    const [formData, setFormData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    // const [formDataId,setFormDataId] = useState('')

    useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await axios.get(`${backend_url}/api/form-data`);
              setFormData(response.data);
          } catch (error) {
              console.error('Error fetching data:', error);
          }
      };

      fetchData();
  }, []);

   
  
    const handleDelete = async (formDataId) => {
      try {
        alert('Are you sure you want to delete this item?');
        
        const response = await axios.delete(`${backend_url}/api/delete_form/${formDataId}`);
        const updatedFormData = formData.filter(item => item._id !== formDataId);
        setFormData(updatedFormData);
    
        alert('Item deleted successfully.');
        
      } catch (error) {
        setError(error.response.data.error);
        alert('An error occurred while deleting the item.');
      }
    };
    


  return (
    <Box borderWidth="2px" bg="ghost" color={'navy'} h={'100vh'} w="100%" >
       <Box borderWidth="1px" fontWeight={'bold'} fontSize={{ base: '20px', md: '25px' }} mb={4} pl={5}>
                <Text>Manage Student</Text>
            </Box>

    <Box   p={4} overflowX="auto" >
    

    <Table variant="striped" colorScheme='teal' size={'sm'}  borderWidth={'2px'} >
      <Thead bg={'navy'} >
        <Tr>
          <Th textAlign={'center'} color={'white'} fontSize={'10'}>Name</Th>
          <Th textAlign={'center'} color={'white'} fontSize={'10'}>Apply Date</Th>

          <Th textAlign={'center'} color={'white'} fontSize={'10'}>Father's Name</Th>
          <Th textAlign={'center'} color={'white'} fontSize={'10'}>Gender</Th>
          <Th textAlign={'center'} color={'white'} fontSize={'10'}>Highest Education</Th>
          <Th textAlign={'center'} color={'white'} fontSize={'10'}>Work Experience</Th>
          <Th textAlign={'center'} color={'white'} fontSize={'10'}>Hobby</Th>
          <Th textAlign={'center'} color={'white'} fontSize={'10'}>DOB</Th>
          <Th textAlign={'center'} color={'white'} fontSize={'10'}>Adhaar No.</Th>
          <Th textAlign={'center'} color={'white'} fontSize={'10'}>Mobile No.</Th>
          <Th textAlign={'center'} color={'white'} fontSize={'10'}>English Communication Skills</Th>
          <Th textAlign={'center'} color={'white'} fontSize={'10'}>Achievements</Th>
          <Th textAlign={'center'} color={'white'} fontSize={'10'}>Career Objective</Th>
          <Th textAlign={'center'} color={'white'} fontSize={'10'}>Action</Th>
        </Tr>
      </Thead>
      <Tbody  >
      {formData.map((item, index) => (
                        <Tr key={index}>
                            <Td textAlign={'center'}  borderColor={'white'} fontSize={'11'}>{item.candidateName}</Td>
                            <Td textAlign={'center'}  borderColor={'white'} fontSize={'11'}>{item.applydate}</Td>
                            <Td textAlign={'center'}  borderColor={'white'} fontSize={'11'}>{item.fathersName}</Td>
                            <Td textAlign={'center'}  borderColor={'white'} fontSize={'11'}>{item.gender}</Td>
                            <Td textAlign={'center'}  borderColor={'white'} fontSize={'11'}>{item.highestEducation}</Td>
                            <Td textAlign={'center'}  borderColor={'white'} fontSize={'11'}>{item.workExperience}</Td>
                            <Td textAlign={'center'}  borderColor={'white'} fontSize={'11'}>{item.hobby}</Td>
                            <Td textAlign={'center'}  borderColor={'white'} fontSize={'11'}>{item.date}</Td>
                            <Td textAlign={'center'}  borderColor={'white'} fontSize={'11'}>{item.aadhaarNumber}</Td>
                            <Td textAlign={'center'}  borderColor={'white'} fontSize={'11'}>{item.mobileNumber}</Td>
                            <Td textAlign={'center'}  borderColor={'white'} fontSize={'11'}>{item.englishCommunication}</Td>
                            <Td textAlign={'center'}  borderColor={'white'} fontSize={'11'}>{item.achievements}</Td>
                            <Td textAlign={'center'}  borderColor={'white'} fontSize={'11'}>{item.careerObjective}</Td>
                            <Td textAlign={'center'}  borderColor={'white'} fontSize={'11'}>
  <Button  onClick={() => handleDelete(item._id)} variant={'outline'} colorScheme='white'>
    <DeleteIcon/>
  </Button>
 
</Td>

          </Tr>
        ))}
      </Tbody>
    </Table>
    </Box>
    <QuickUser/>
    </Box>
  );
};

export default User;
