import React ,{useState}from 'react';
import { Box, Heading, Text, Button, VStack, HStack, Icon, Image, SimpleGrid ,Flex, Grid, GridItem,Img} from '@chakra-ui/react';
import { FaChalkboardTeacher, FaUsers, FaLaptopCode } from 'react-icons/fa';
import { AiFillClockCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import Service from './Service';
import AboutPage from './AboutPage';
import Course from './Course';

const services = [
  {
    src: 'https://www.rishabhsoft.com/wp-content/uploads/2022/02/MEAN-vs-MERN-Banner-Image.jpg',
    description: 'MERN FULL STACK DEVELOPER',
    about: 'Develop and maintain web applications using the MERN Stack (MongoDB, Express, React, Node.js). Join our dynamic team to gain experience and contribute to our projects..'
  },
  {
    src: 'https://www.zibtek.com/blog/content/images/2020/08/eCommerce-Development-Services.png',
    description: 'ECOMMERCE SERVICES',
    about: "Want to start an eCommerce store? Witness an expanded growth of your business with our customized eCommerce services . Our full-suite of eCommerce development services include:"
  },
  {
    src: 'https://www.gracethemesdemo.com/documentation/online-consulting/images/slider.jpg',
    description: 'BUSINESS CONSULTATION SERVICES',
    about: "With our business consultation services we help you adapt and thrive in the dynamic markets of today. We work with you all through the way to navigate you overcome complex business challenges."
  },
  {
    src: 'https://stackpos.in/assets/images/services(1).jpg',
    description: 'WEB DEVELOPMENT',
    about: "By using cutting-edge technologies, our in-house team of developers delivers intuitive and highly agile websites. By keeping your vision in focus we bring out a digital transformation for your business that helps you further broaden your"
  },
  {
    src: 'https://www.analyticssteps.com/backend/media/thumbnail/4530462/5951113_1605265443_AI%20(7).jpg',
    description: 'AI & MACHINE LEARNING ',
    about: "Artificial Intelligence (AI): Simulates human intelligence in machines to perform tasks like understanding language, recognizing patterns, and making decisions.   Machine Learning (ML): A subset of AI, focuses on training algorithms with data to learn patterns and make autonomous decisions, improving over time with experience."
  },
  {
    src: 'https://stackpos.in/assets/images/services(5).jpg',
    description: 'MOBILE APPLICATIONS',
    about: "With an industry-best approach to mobile application development services , we bring together the best development and design practices to provide a full cycle of mobile app development for both, Android and IOS devices."
  }
];



const CourseModule = ({ imageName, moduleName, moduleDescription, duration }) => (
  <Box border="1px solid #ccc" bg={'white'} p={4} mb={4} mr={1} boxShadow={'md'}  width={{ base: "100%", md: "50%", lg: "33%" }}>
    <Flex direction="column" align="center" justify="center">
      <Box>

      <Image src={imageName} alt={moduleName}  mb={4} />
      </Box>
      <Heading as="h3" size="md" textAlign="center" color={'navy'} mb={2}>{moduleName}</Heading>
      <Text textAlign="center" mb={2} fontWeight={'500'}>{moduleDescription}</Text>
      <Flex align="center" justify="center"  mb={3}>
        <Icon as={AiFillClockCircle} boxSize={5} mr={1} />
        <Text>{duration}</Text>
      </Flex>
    </Flex>
<Link to={'/enroll_now'}>
        <Box as="button" p={2} color={'white'} fontWeight={'500'} fontSize={'13'} bg={'orange'} position={'bottom'} borderRadius={4}>Enroll now</Box>
</Link>
  </Box>
);


const HomePage = () => {
  const [showCourseDetails, setShowCourseDetails] = useState(false);

  const handleLearnMoreClick = () => {
    setShowCourseDetails(!showCourseDetails);
  };


  return (
    <Box>
      {/* Banner Section */}
      <Box bg="blue.500" color="white" py={10} textAlign="center">
        <Heading>Welcome to Our Full Stack & AI Courses</Heading>
        <Text mt={4} fontWeight={'500'}>Learn from the experts and get placed in top companies!</Text>
        <Link to={'/enroll_now'}>
        <Button bg={'orange'} color={'white'} _hover={{ bg: 'navy', color: 'orange'}} mt={6} size="lg">Enroll Now</Button>
        </Link>
      </Box>

      {/* Introduction Section */}
      <Box py={10} px={5} bg="gray.100">
        <VStack spacing={4}>
          
          <Heading color={'#4169e1'}>About Us</Heading>
          <Text textAlign="center" maxW="700px" fontWeight={'600'}>
            We offer comprehensive courses in Full Stack MERN development and AI, designed to help you master the skills needed to succeed in the tech industry. Our courses are available online with placement services to ensure your success.
          </Text>
          <Box p={4}>
      <Button colorScheme="teal" onClick={handleLearnMoreClick}>
        Learn More
      </Button>
      {showCourseDetails && 
      <Box>

<Flex direction={["column", "column", "row"]} wrap="wrap" justify="space-between">

    <Flex flexWrap="wrap" justify="space-between" p={4}>
      <CourseModule 
        imageName="https://eww-wp-new.s3.ap-south-1.amazonaws.com/wp-content/uploads/2020/05/10114231/guide-on-full-stack-development.jpg" 
        moduleName="Frontend Development Basics" 
        moduleDescription="Introduction to HTML, CSS, and JavaScript. Building simple web pages and understanding client-side development."
        duration="2 weeks"
      />
      <CourseModule 
        imageName="https://dailyskill.pk/assets/images/courses/1710756547.png" 
        moduleName="React Fundamentals" 
        moduleDescription="Understanding React components, state management, and creating interactive user interfaces."
        duration="2 weeks"
      />
      <CourseModule 
        imageName="https://jumpgrowth.com/wp-content/uploads/2019/09/advantages-of-nodejs.webp" 
        moduleName="Node.js and Express Basics" 
        moduleDescription="Building server-side applications with Node.js, using Express for routing and middleware."
        duration="4 weeks"
      />
      <CourseModule 
        imageName="https://www.sqlsplus.com/wp-content/uploads/2020/09/MongoDB-Database-sample.jpg" 
        moduleName="Database Design with MongoDB" 
        moduleDescription="Introduction to NoSQL databases, using MongoDB for data storage and retrieval."
        duration="2 weeks"
      />
      <CourseModule 
        imageName="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/168820317/original/dab53b6d05429da3bc9cf782c8f4795eaab5e898/create-restful-api-for-your-backend-server-in-nodejs.jpg" 
        moduleName="RESTful APIs with Express and MongoDB" 
        moduleDescription="Creating RESTful APIs using Node.js, Express, and integrating MongoDB for backend data storage."
        duration="2 weeks"
      />
      <CourseModule 
        imageName="https://statanalytica.com/blog/wp-content/uploads/2023/01/Full-Stack-Web-Development-Project-Ideas.webp" 
        moduleName="Full Stack Project Development" 
        moduleDescription="Building a complete full stack application from scratch, integrating frontend and backend technologies."
        duration="9 weeks"
      />
    </Flex>



   
   </Flex>






      </Box>
        }
    </Box>
        </VStack>

      </Box>

      {/* <Service/> */}
      <AboutPage/>

      {/* Highlights Section */}
      



      {/* <Box p="4" shadow="md" borderWidth="1px" borderRadius="md">
      <Text fontWeight="bold" fontSize="lg">Our Services</Text>
      <Grid templateColumns="repeat(2, 1fr)" gap={4} mt="4"> */}
        <Box>
        <Flex
      w='100%'
      bg='ghost'
      color='black'
      wrap='wrap'
      direction={'column'}
      p={4}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Heading
        // fontSize={{ base: '20px', md: '25px' }}
        color={'#4169e1'}
        textAlign={'center'}
        mb={4}
      >
        OUR SERVICES
      </Heading>
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
        gap={6}
        w='100%'
        maxW='1200px'
      >
        {services.map((service, index) => (
          <GridItem key={index} textAlign='left'>
            <Box boxShadow={'sm'} p={6} letterSpacing={1} >
              <Img src={service.src} alt={`Service ${index + 1}`} mb={4} />
              <Text fontWeight={'700'} fontSize={{ base: '15px', md: '23px' }} color={'blue.400'}>{service.description}</Text>
              <Text fontWeight={'400'} fontSize={{ base: '15px', md: '16px' }} color={'gray'}>{service.about}</Text>
            </Box>
          </GridItem>
        ))}
      </Grid>
    </Flex>
    {/* </Box> */}
        </Box>
        {/* Add more services as needed */}
      {/* </Grid>
    </Box> */}


<Box bg="gray.100" py={10} px={5}>
        <Heading textAlign="center" mb={6}  color={'#4169e1'}>Why Choose Us?</Heading>
        <SimpleGrid columns={[1, 2, 3]} spacing={8}>
          <VStack>
            <Icon as={FaChalkboardTeacher} boxSize={12} color="teal.500" />
            <Heading size="md">Expert Instructors</Heading>
            <Text textAlign="center" fontWeight={'500'}>
              Learn from industry professionals with years of experience.
            </Text>
          </VStack>
          <VStack>
            <Icon as={FaUsers} boxSize={12} color="teal.500" />
            <Heading size="md">Community Support</Heading>
            <Text textAlign="center" fontWeight={'500'}>
              Join a community of learners and get support from peers and mentors.
            </Text>
          </VStack>
          <VStack>
            <Icon as={FaLaptopCode} boxSize={12} color="teal.500" />
            <Heading size="md">Hands-on Projects</Heading>
            <Text textAlign="center" fontWeight={'500'}>
              Gain practical experience with real-world projects.
            </Text>
          </VStack>
        </SimpleGrid>
      </Box>



      {/* Testimonials Section */}
      {/* <Box py={10} px={5}>
        <Heading textAlign="center" mb={6}  color={'#4169e1'}>What Our Students Say?</Heading>
        <SimpleGrid columns={[1, 2, 3]} spacing={8}>
          <VStack>
            <Image borderRadius="full" boxSize="100px" src="https://via.placeholder.com/150" alt="Student 1" />
            <Text textAlign="center">"This course changed my life! The instructors are amazing and the content is top-notch."</Text>
            <Text>- Student 1</Text>
          </VStack>
          <VStack>
            <Image borderRadius="full" boxSize="100px"  src="https://via.placeholder.com/150"  alt="Student 2" />
            <Text textAlign="center">"I got a job within a month of completing the course. Highly recommend!"</Text>
            <Text>- Student 2</Text>
          </VStack>
          <VStack>
            <Image borderRadius="full" boxSize="100px" src="https://via.placeholder.com/150" alt="Student 3" />
            <Text textAlign="center">"The projects were challenging but rewarding. I learned so much!"</Text>
            <Text>- Student 3</Text>
          </VStack>
        </SimpleGrid>
      </Box> */}

      {/* Contact Information Section */}
      {/* <Box bg="blue.500" color="white" py={10} textAlign="center">
        <Heading>Contact Us</Heading>
        <Text mt={4}>Have any questions? Feel free to reach out to us!</Text>
        <HStack justify="center" spacing={8} mt={6}>
          <VStack>
            <Heading size="md">Email</Heading>
            <Text>info@yourcompany.com</Text>
          </VStack>
          <VStack>
            <Heading size="md">Phone</Heading>
            <Text>+123 456 7890</Text>
          </VStack>
        </HStack>
      </Box> */}
    </Box>
  );
};

export default HomePage;
