import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import App3 from './App3';
import Navbar2 from './Pages/Navbar2';
import Login from './Pages/Login';

const Main2 = () => {
  const { isAuthenticated, data } = useSelector((state) => state.authReducer);
  let role = '';
  if (data && data.user) {
    role = data.user.role;
  }

  console.log(role);

  return (
    <ChakraProvider>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/*" element={<App3 />} />

          {/* Protected Admin Routes */}
          <Route
            path="/admin/*"
            element={
              isAuthenticated && role === 'admin' ? (
                <Navbar2 />
              ) : (
                <Navigate to="/login_admin" />
              )
            }
          />
          
          {/* Login Route */}
          <Route path="/login_admin" element={<Login />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
};

export default Main2;
