import * as React from 'react'

// 1. import `ChakraProvider` component
import { ChakraProvider } from '@chakra-ui/react'
import Header from './website/pages/Header.js'
import Navbar from './website/pages/Navbar.js'
import Footer from './website/pages/Footer.js'
import { BrowserRouter ,Route} from 'react-router-dom'
import Menubar from './website/pages/Menubar.js'
import Main3 from './website/pages/Main3.js'
import Home from './website/pages/Home.js'
import AboutUs from './website/pages/AboutUs.js'


function App2() {

  return (
    <ChakraProvider>
        {/* <BrowserRouter> */}

      {/* <Header/>
      <Navbar/>
      <Menubar/> */}
    <Home/>
    {/* <Route path="/about" element={<AboutUs />} /> */}
      {/* <Footer/> */}


      {/* </BrowserRouter> */}
    </ChakraProvider>
  )
}
export default App2;