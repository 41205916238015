import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Flex, Text, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const base_url = process.env.REACT_APP_BACKEND_URL;

const Users = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedUserData, setUpdatedUserData] = useState({
    name: '',
    email: '',
    password: ''
  });



  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${base_url}/api/find_registeruser`);
        setUsers(response.data.users);
        // Fetch decrypted passwords for each user
        fetchPasswords(response.data.users);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);


  const fetchPasswords = async (users) => {
    try {
      const updatedUsers = [];
      for (const user of users) {
        const response = await axios.get(`${base_url}/api/user/${user._id}/password`);
        const decryptedPassword = response.data.password;
        updatedUsers.push({ ...user, password: decryptedPassword });
      }
      setUsers(updatedUsers);
    } catch (error) {
      console.error('Error fetching passwords:', error);
    }
  };

  const handleOpenModal = (user) => {
    setSelectedUser(user);
    setUpdatedUserData({
      name: user.name,
      email: user.email,
      password: user.password
    });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleUpdateUser = async () => {
    try {
      await axios.put(`${base_url}/api/registeruser/${selectedUser._id}`, updatedUserData);
    //   const response = await axios.get(`${base_url}/api/find_registeruser`);
    //   setUsers(response.data.users);
      setIsModalOpen(false);
      toast.success('User updated successfully!');
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error('Error updating user!');
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await axios.delete(`${base_url}/api/delete_registeruser/${userId}`);
    //   const response = await axios.get(`${base_url}/api/find_registeruser`);
    //   setUsers(response.data.user);
      toast.success('User deleted successfully!');
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('Error deleting user!');
    }
  };

  return (
    <Box w="100%" borderWidth="2px" bg="ghost" color={'navy'} h={'100vh'}  >
     <Box borderWidth="1px" fontWeight={'bold'} fontSize={'25'} mb={4} pl={5}>

<Text >Manage Users</Text>
</Box>
      <Flex direction="column" p={2}>
        <Flex wrap="wrap" bg="navy" color={'white'} borderWidth="1px"  p="2" fontWeight="700" fontSize={'12'}>
          <Box flex={1}>Name</Box>
          <Box flex={1}>Email</Box>
          {/* <Box flex={1}>Mobile</Box> */}
          <Box flex={1}>Password</Box>
          <Box flex={1}>Actions</Box>
        </Flex>
        {users.map(user => (
          <Flex key={user._id} wrap="wrap" p="2" borderWidth="1px" borderRadius="md" borderBottomWidth="2px"  fontSize={'13'} color={'navy'} >
            <Box flex={1}>{user.name}</Box>
            <Box flex={1}>{user.email}</Box>
            {/* <Box flex={1}>{user.phone}</Box> */}
            <Box flex={1}>{user.password}</Box>
            <Box flex={1}>
              <Button variant={'outline'} borderWidth={'2px'} colorScheme='navy' h={8} onClick={() => handleOpenModal(user)} mr={2}><EditIcon boxSize={4} /></Button>
              <Button variant={'outline'} borderWidth={'2px'} colorScheme='navy' h={8} onClick={() => handleDeleteUser(user._id)}><DeleteIcon boxSize={4} /></Button>
            </Box>
          </Flex>
        ))}
      </Flex>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent bg={'gray.200'} color={'blue.700'}>
          <ModalHeader>Update User Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input borderColor={'blue'} defaultValue={selectedUser ? selectedUser.name : ''} onChange={(e) => setUpdatedUserData({ ...updatedUserData, name: e.target.value })} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Email</FormLabel>
              <Input borderColor={'blue'} defaultValue={selectedUser ? selectedUser.email : ''} onChange={(e) => setUpdatedUserData({ ...updatedUserData, email: e.target.value })} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Password</FormLabel>
              <Input borderColor={'blue'} defaultValue={selectedUser ? selectedUser.password : ''} onChange={(e) => setUpdatedUserData({ ...updatedUserData, password: e.target.value })} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleUpdateUser}>Update</Button>
            <Button onClick={handleCloseModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ToastContainer /> {/* Toast container to display alerts */}
    </Box>
  );
}

export default Users;
