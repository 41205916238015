import * as React from 'react'

// 1. import `ChakraProvider` component
import { ChakraProvider } from '@chakra-ui/react'
import Header from './Header';
import Navbar from './Navbar';
import Body from './Body';
import Service from './Service';
import Footer from './Footer';
import OverlayComponent from './ImageLayout';
import Carousel from './Carousel';
import Body2 from './Body2';
import Course from './Course';
import HomePage from './HomePage';
import AboutPage from './AboutPage';
import CourseDetailsComponent from './OnlineTaining';

function Home() {

  return (
    <ChakraProvider>

      {/* <Header/>
      <Navbar/> */}
      <Carousel/>
      <HomePage/>
      {/* <CourseDetailsComponent/> */}
      {/* <FullStackCourseMenu/>
      <AICourseMenu/> */}
      {/* <AboutPage/> */}
      {/* <Body/> */}
      {/* <Service/> */}
      {/* <Course/> */}
      {/* <Body2/> */}
      <OverlayComponent/>
      {/* <Footer/> */}

      {/* <Main/> */}

  
    </ChakraProvider>
  )
}
export default Home;