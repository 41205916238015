import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import App3 from './App3';
import Login from './Pages/Login';
import Navbar2 from './Pages/Navbar2';
import Main2 from './Main2';
import User from './Pages/User';
import Users from './Pages/Users';

const App = () => {
  const { isAuthenticated, data } = useSelector((state) => state.authReducer);
  let role = '';
  if (data && data.user) {
    role = data.user.role;
  }

  console.log(role);

  return (
    <ChakraProvider>
      

<Main2/>
{/* <Users/> */}

    </ChakraProvider>
  );
};

export default App;
