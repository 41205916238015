export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_FAIL = "LOGIN_REQUEST_FAIL";

export const LOADUSER_REQUEST = "LOADUSER_REQUEST";
export const LOADUSER_REQUEST_SUCCESS = "LOADUSER_REQUEST_SUCCESS";
export const LOADUSER_REQUEST_FAIL = "LOADUSER_REQUEST_FAIL";


export const LOGOUTUSER_REQUEST =         "LOGOUTUSER_REQUEST";
export const LOGOUTUSER_REQUEST_SUCCESS = "LOGOUTUSER_REQUEST_SUCCESS";
export const LOGOUTUSER_REQUEST_FAIL =    "LOGOUTUSER_REQUEST_FAIL";