import { LOADUSER_REQUEST, LOADUSER_REQUEST_FAIL, LOADUSER_REQUEST_SUCCESS, LOGIN_REQUEST,LOGIN_REQUEST_FAIL,LOGIN_REQUEST_SUCCESS, LOGOUTUSER_REQUEST, LOGOUTUSER_REQUEST_FAIL, LOGOUTUSER_REQUEST_SUCCESS  } from "./actionType";

  
  const initialState = {
    isLoading: false,
    isError: false,
    isAuthenticated:false,
    isMessage:'',
  data:[],
  };
  
  export const reducer = (state = initialState,action) => {
    switch (action.type) {
      case LOGIN_REQUEST,LOADUSER_REQUEST:
        case LOGOUTUSER_REQUEST:
        return { ...state, isLoading: true,isError:false };
       case LOGIN_REQUEST_SUCCESS:
        case LOADUSER_REQUEST_SUCCESS:
        
        return {
      ...state,
        isAuthenticated :action.payload.success,
        isLoading: false,
        isError:false,
         data:action.payload
        };
      case LOGIN_REQUEST_FAIL:
      case LOADUSER_REQUEST_FAIL:
        case LOGOUTUSER_REQUEST_FAIL:
        return { ...state, isLoading: false,isError:true,isAuthenticated:false };


 case LOGOUTUSER_REQUEST_SUCCESS:

return{

  isAuthenticated :false,

}


     
      default:
        return state;
    }
  };
  