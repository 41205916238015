import { Table, Thead, Tbody, Tr, Th, Td, Box,HStack,Img,Text, Button, Flex } from '@chakra-ui/react';
import { useState,useEffect } from 'react';
import {DeleteIcon } from '@chakra-ui/icons'
import axios from 'axios';
const backend_url=process.env.REACT_APP_BACKEND_URL;


const QuickUser = () => {
    const [formData, setFormData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    // const [formDataId,setFormDataId] = useState('')

    useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await axios.get(`${backend_url}/api/quick-data`);
              setFormData(response.data);
          } catch (error) {
              console.error('Error fetching data:', error);
          }
      };

      fetchData();
  }, []);

   
  
    const handleDelete = async (formDataId) => {
      try {
        alert('Are you sure you want to delete this item?');
        
        const response = await axios.delete(`${backend_url}/api/delete_quickform/${formDataId}`);
        const updatedFormData = formData.filter(item => item._id !== formDataId);
        setFormData(updatedFormData);
    
        alert('Item deleted successfully.');
        
      } catch (error) {
        setError(error.response.data.error);
        alert('An error occurred while deleting the item.');
      }
    };
    


  return (
    <Flex p={4}  justifyContent={'center'}  justifyItems={'center'} alignItems={'center'}>
    
<Box  w={'100%'}>
    <Table variant="striped" colorScheme='teal' size={'sm'}  borderWidth={'2px'}>
      <Thead bg={'navy'}  >
        <Tr>
          <Th textAlign={'center'} color={'white'} p={2} >Name</Th>
          {/* <Th textAlign={'center'} color={'white'} p={2} >Email</Th> */}
   
          <Th textAlign={'center'} color={'white'}>Highest Education</Th>
          <Th textAlign={'center'} color={'white'} fontSize={'12'}>Apply Date</Th>
          
          <Th textAlign={'center'} color={'white'}>Mobile No.</Th>
          <Th textAlign={'center'} color={'white'}>Career Objective</Th>
        
          <Th textAlign={'center'} color={'white'}>Action</Th>
        </Tr>
      </Thead>
      <Tbody  >
      {formData.map((item, index) => (
                        <Tr key={index}>
                            <Td textAlign={'center'} borderColor={'gray.200'} fontSize={'12'}>{item.candidateName}</Td>
                            {/* <Td textAlign={'center'} borderColor={'gray.200'} fontSize={'12'}>{item.email}</Td> */}
                            <Td textAlign={'center'} borderColor={'gray.200'} fontSize={'12'}>{item.highestEducation}</Td>
                            <Td textAlign={'center'} borderColor={'gray.200'} fontSize={'12'}> {new Date(item.applydate).toLocaleDateString()}</Td>
                           
                          
                            <Td textAlign={'center'} borderColor={'gray.200'} fontSize={'12'}>{item.mobileNumber}</Td>
                        
                            <Td textAlign={'center'} borderColor={'gray.200'} fontSize={'12'}>{item.careerObjective}</Td>
                            <Td textAlign={'center'} borderColor={'gray.200'} fontSize={'12'}>
  <Button  onClick={() => handleDelete(item._id)} variant={'outline'} colorScheme='white'>
    <DeleteIcon/>
  </Button>
 
</Td>

          </Tr>
        ))}
      </Tbody>
    </Table>
    </Box>
    </Flex>
  );
};

export default QuickUser;
