import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ChakraProvider, Box, Heading, Input, Button, List, ListItem, Text, Textarea, VStack, Img,useToast,HStack,Flex,Center ,Grid, GridItem } from '@chakra-ui/react';
const backend_url=process.env.REACT_APP_BACKEND_URL;

function Setting() {
  const [urls, setUrls] = useState();
  const [newUrl, setNewUrl] = useState('');
  const [title, setTitle] = useState('');
  const [des, setDes] = useState('');
  const [image, setImage] = useState(null);
  const [uploadedData, setUploadedData] = useState(null);
  const toast = useToast();

  
  const fetchUrls = async () => {
    try {
      const response = await axios.get(`${backend_url}/find/url`);
      setUrls(response.data);
      console.log(response.data)
    } catch (error) {
      console.error('Error fetching URLs:', error);
    }
  };
  
  useEffect(() => {
    fetchUrls();
  }, []);





  const deleteUrl = async (id) => {
    try {
      await axios.delete(`${backend_url}/delete/url/${id}`)
      fetchUrls();
    } catch (error) {
      console.error('Error deleting URL:', error);
      console.log(error)
    }
  };






  const handleUpload = async () => {
    try {
      
      const formData = new FormData();
      formData.append('title', title);
      formData.append('des', des);
      formData.append('image', image);

      
      await axios.post(`${backend_url}/upload`, formData);

     
    setTitle("");
    setDes("");
    setImage("");

    toast({
      title: 'Upload successful!',
      status: 'success',
      duration: 2000,
      isClosable: true,
  });
      
  fetchUrls()

 } catch (error) {
      console.error('Error uploading:', error);
      toast({
        title: 'Upload failed. Please try again later.',
        status: 'error',
        duration: 2000,
        isClosable: true,
    });
    }
  };


  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <ChakraProvider>

      
     <Box  w="100%" borderWidth="2px" bg="ghost" color={'navy'} h={'100vh'}  >
        <Box borderWidth="1px" fontWeight={'bold'} fontSize={'25'} mb={4} pl={5}>

<Text >Manage Images & Text</Text>
</Box>
     

<Box  p={5}>
  <HStack>

<Input type='text'  value={title}
          onChange={(e) => setTitle(e.target.value)} borderColor={'blue'} size={'sm'} placeholder='Title'/>
<Input  value={des}
          onChange={(e) => setDes(e.target.value)} borderColor={'blue'} size={'sm'} placeholder='Description'  />
<Input type='file' border={'none'} onChange={handleImageChange} size={'sm'}  />

<Button colorScheme="blue" size="xl" p={'2'} variant={'outline'} onClick={handleUpload}>Upload</Button>


</HStack>
</Box>
 <Box>

   <Grid
   templateColumns="repeat(3, 1fr)"
   gap={4}
   alignItems="center"
 >
 {urls && urls.map((item, index) => (
   


 
     <GridItem key={index} bg="gray.100" p={4} borderRadius="md">
      <Box>

<VStack>

       <Text>{item.imageurl.replace(/(uploads|\/)/g, '')}</Text>
       <Text fontSize={'10'}>{item.title}</Text>

</VStack>
   <Button colorScheme="red"  onClick={() => deleteUrl(item._id)}>
        Delete
      </Button>
      </Box>
     </GridItem>
  
   
   

))}

</Grid>
 </Box>

 </Box>




    </ChakraProvider>
  );
}

export default Setting;
