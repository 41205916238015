import React from 'react';
import { 
  ChakraProvider, 
  Box, 
  Flex, 
  Text, 
  Button, 
  Img, 
  HStack 
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import logo from './assets/logo.png';

function Navbar() {
  return (
    <ChakraProvider>
      <Box bg={'white'} color={'#002147'} borderWidth={'1px'}>
        <Flex h={'100%'} justifyContent="space-between" alignItems="center">
          <Box ml={4}>
            <Img src={logo} w={{ base: 'auto', md: 220 }} h={{ base: 'auto', md: 100 }} />
          </Box>
          <HStack spacing={4} mr={4}>
            <Link to={'/student_registration_form'}>
              <Button
                bg='teal.500'
                fontWeight='600'
                color={'white'}
                p={3}
                _hover={{
                  bg: 'teal.300',
                  color: 'white'
                }}
              >
                <Text>Apply Now</Text>
              </Button>
            </Link>
            <Link to={'/login_admin'}>
              <Button
                variant={'outline'}
                color={'teal.500'}
                borderWidth={'2px'}
                borderColor={'teal.500'}
                fontWeight='600'
                p={3}
                _hover={{
                  bg: 'teal.500',
                  color: 'white'
                }}
              >
                <Text>Login</Text>
              </Button>
            </Link>
          </HStack>
        </Flex>
      </Box>
    </ChakraProvider>
  );
}

export default Navbar;
