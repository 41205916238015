import React from 'react';
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom';

import Home from './Home'
import CourseDetailsComponent from './OnlineTaining';
import SupportMenu from './Support';
import Service from './Service';
import Course from './Course';
import AboutUs from './AboutUs';
import ApplyForm from './ApplyForm';
import Form from './StudentForm';



function Main3() {
    return (
        
        // <Router>

            <Routes>
                {/* <Route path="/" element={<Home/>} /> */}
                <Route path="/online_training" element={<CourseDetailsComponent/>} />
                <Route path="/contact" element={<SupportMenu  />} />
                <Route path="/services" element={<Service />} />
                <Route path="/courses" element={<Course />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/enroll_now" element={<ApplyForm />} />
                <Route path="/student_registration_form" element={<Form />} />
            </Routes>
    //  </Router>
    );
}

export default Main3;
