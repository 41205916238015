import { Box, Text, HStack, Stack, SimpleGrid, Heading,useBreakpointValue } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { FaUniversity } from "react-icons/fa";
import { MdContentPasteSearch } from "react-icons/md";
import { HiUserGroup } from "react-icons/hi";
import { TbSettings } from "react-icons/tb";
import Barchart from './Barchart';
import { AiFillProfile } from "react-icons/ai";
import { PiStudentBold } from "react-icons/pi";
import axios from 'axios';
import { Link } from 'react-router-dom';
import Users from './Users';
import QuickUser from './QuickUser';
import User from './User';

const backend_url = process.env.REACT_APP_BACKEND_URL;

const Home2 = () => {
    const [userCount, setUserCount] = useState(0);
    const [universityCount, setUniversityCount] = useState(0);
    const [enquiryCount, setEnquiryCount] = useState(0);

    useEffect(() => {
        async function fetchCounts() {
          try {
            const userResponse = await axios.get('/api/quick-data');
            console.log('User count data:', userResponse.data); // Log the data to debug
            setUserCount(userResponse.data.length);
    
            const universityResponse = await axios.get('/api/form-data');
            console.log('University count data:', universityResponse.data); // Log the data to debug
            setUniversityCount(universityResponse.data.length);
    
            // Uncomment and complete this section if needed
            // const enquiriesResponse = await axios.get('/api/usercount');
            // console.log('Enquiry count data:', enquiriesResponse.data); // Log the data to debug
            // setEnquiryCount(enquiriesResponse.data.length);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
    
        fetchCounts();
      }, []);

    return (
        <Box w="100%" borderWidth="2px" bg="ghost" color={'navy'} h={'100vh'}>
            <Box borderWidth="1px" fontWeight={'bold'} fontSize={{ base: '20px', md: '25px' }} mb={4} pl={5}>
                <Text>Dashboard</Text>
            </Box>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={4} p={{ base: "2", sm: "4" }}>
                <Link to={'/manage_student'}>
                <Box bg={"white"} p={{ base: "2", sm: "4" }} boxShadow="md">
                    <HStack>
                        <Box bg={'orange'} rounded={'50%'} p={{ base: '2', md: '4' }}>
                            <PiStudentBold size={useBreakpointValue({ base: '24px', md: '40px' })} color="white" />
                        </Box>
                        <Stack>
                            <Text fontWeight={'bold'} color={'navy'} fontSize={{ base: '16px', md: '18' }}>Students: {universityCount}</Text>
                        </Stack>
                    </HStack>
                </Box>
                </Link>
                <Box bg={"white"} p={{ base: "2", sm: "4" }} boxShadow="md">
                    <HStack>
                        <Box bg={'red.500'} rounded={'50%'} p={{ base: '2', md: '4' }}>
                            <PiStudentBold size={useBreakpointValue({ base: '24px', md: '40px' })} color="white" />
                        </Box>
                        <Stack>
                            <Text fontWeight={'bold'} color={'navy'} fontSize={{ base: '16px', md: '18' }}>Quick Students: {userCount}</Text>
                        </Stack>
                    </HStack>
                </Box>
                <Box bg={"white"} p={{ base: "2", sm: "4" }} boxShadow="md">
                    <HStack>
                        <Box bg={'green.400'} rounded={'50%'} p={{ base: '2', md: '4' }}>
                            <HiUserGroup size={useBreakpointValue({ base: '24px', md: '40px' })} color="white" />
                        </Box>
                        <Stack>
                            <Text fontWeight={'bold'} color={'navy'} fontSize={{ base: '16px', md: '18' }}>Users: 1</Text>
                        </Stack>
                    </HStack>
                </Box>
                <Box bg={"white"} p={{ base: "2", sm: "4" }} boxShadow="md">
                    <HStack>
                        <Box bg={'blue.500'} rounded={'50%'} p={{ base: '2', md: '4' }}>
                            <TbSettings size={useBreakpointValue({ base: '24px', md: '40px' })} color="white" />
                        </Box>
                        <Stack>
                            <Text fontWeight={'bold'} color={'navy'} fontSize={{ base: '16px', md: '18' }}>Now Showing</Text>
                        </Stack>
                    </HStack>
                </Box>
            </SimpleGrid>


{/* <QuickUser/> */}
<User/>

<Users/>



        </Box>
    );
}

export default Home2;
