import { 
    Container, 
    HStack, 
    Img, 
    Text, 
    Textarea,  
    useBreakpointValue, 
    VStack, 
    Radio, 
    RadioGroup, 
    Stack, 
    Box, 
    Input,
    Checkbox, 
    Button, 
    FormLabel, 
    FormControl, 
    Select, 
    Flex, 
    Heading
} from '@chakra-ui/react';
import React, { useState } from 'react';
import axios from 'axios';
import { FaReact } from 'react-icons/fa';
import { SiMongodb, SiExpress } from 'react-icons/si';
import { SiNodedotjs } from 'react-icons/si';

// import logo from './assets/image.png'
const backend_url = process.env.REACT_APP_BACKEND_URL;

const Form = () => {
    const [formData, setFormData] = useState({
        candidateName: '',
        fathersName: '',
        gender: '',
        highestEducation: '',
        workExperience: '',
        hobby: '',
        date: "",
        aadhaarNumber: '',
        mobileNumber: '',
        englishCommunication: '',
        achievements: '',
        careerObjective: ''
    });

    const containerMaxW = useBreakpointValue({ base: '100%', md: '100%' });
    const formWidth = useBreakpointValue({ base: '100%', md: '70%' });

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData) {
            alert('Please fill in all fields');
            return;
        }

        try {
            const response = await axios.post(`${backend_url}/api/form-submit`, formData);
            console.log('Form submitted successfully:', response.data);
            alert('Form submitted successfully');
            setFormData({
                candidateName: '',
                fathersName: '',
                highestEducation: '',
                workExperience: '',
                hobby: '',
                date: "",
                gender: "",
                englishCommunication: "",
                aadhaarNumber: '',
                mobileNumber: '',
                achievements: '',
                careerObjective: ''
            });
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('An error occurred while submitting the form. Please try again later.');
        }
    };

    const handleClear = () => {
        setFormData({
            candidateName: '',
            fathersName: '',
            highestEducation: '',
            workExperience: '',
            hobby: '',
            date: '',
            gender: '',
            englishCommunication: '',
            aadhaarNumber: '',
            mobileNumber: '',
            achievements: '',
            careerObjective: ''
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'aadhaarNumber' && (value.length > 12 || isNaN(value))) {
            return;
        }
        if (name === 'mobileNumber' && (value.length > 10 || isNaN(value))) {
            return;
        }
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <Flex
            // maxW={containerMaxW}
            w={'100%'}
            py={2}
            bgImage="url('https://cdn.wallpapersafari.com/68/66/PLgQbl.jpg')"
            bgSize="cover"
            bgPosition="center"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <Flex
                justifyContent={'center'}
                alignContent={'center'}
                flexDirection={{ base: 'column', md: 'row' }}
                p={5}
                w={'100%'}
            >
                <Box
                    bg={'white'}
                    p={8}
                    borderRadius="md"
                    boxShadow="md"
                    w={{ base: 'auto', md: '60%' }}
                >
                    <Box p={4}>
                        <Heading textAlign={'center'} color={'orange'}>Student Registration Form</Heading>
                    </Box>

                    <FormControl isRequired>
                        {[
                            { label: 'Candidate Name:', name: 'candidateName', type: 'text' },
                            { label: 'Father\'s Name:', name: 'fathersName', type: 'text' },
                            { label: 'Highest Education Qualification:', name: 'highestEducation', type: 'text' },
                            { label: 'Work Experience/Certification:', name: 'workExperience', type: 'text' },
                            { label: 'Hobby:', name: 'hobby', type: 'text' },
                            { label: 'Aadhaar Number:', name: 'aadhaarNumber', type: 'number', pattern: "\\d{12}" },
                            { label: 'Mobile Number:', name: 'mobileNumber', type: 'number', pattern: "\\d{10}" },
                            { label: 'Achievements (if any):', name: 'achievements', type: 'text' },
                        ].map(({ label, name, type, pattern }) => (
                            <HStack spacing={4} mb={2} key={name}>
                                <Box flex="1">
                                    <FormLabel fontSize={'sm'}>{label}</FormLabel>
                                </Box>
                                <Box flex="2">
                                    <Input
                                        borderRadius={'full'}
                                        borderColor={'gray'}
                                        type={type}
                                        pattern={pattern}
                                        name={name}
                                        value={formData[name]}
                                        onChange={handleChange}
                                    />
                                </Box>
                            </HStack>
                        ))}

                        <HStack spacing={4} mb={2}>
                            <Box flex="1">
                                <FormLabel fontSize={'sm'}>Gender:</FormLabel>
                            </Box>
                            <Box flex="2">
                                <Select
                                    borderRadius={'full'}
                                    value={formData.gender}
                                    onChange={handleChange}
                                    name='gender'
                                    borderColor={'gray'}
                                    fontSize={'13'}
                                    placeholder='Select Gender'
                                >
                                    <option value='male'>Male</option>
                                    <option value='female'>Female</option>
                                    <option value='other'>Other</option>
                                </Select>
                            </Box>
                        </HStack>

                        <HStack spacing={4} mb={2}>
                            <Box flex="1">
                                <FormLabel fontSize={'sm'}>Date of Birth:</FormLabel>
                            </Box>
                            <Box flex="2">
                                <Input
                                    borderRadius={'full'}
                                    borderColor={'gray'}
                                    type='date'
                                    name='date'
                                    value={formData.date}
                                    onChange={handleChange}
                                />
                            </Box>
                        </HStack>

                        <HStack spacing={4} mb={2}>
                            <Box flex="1">
                                <FormLabel fontSize={'sm'}>English Communication Skill:</FormLabel>
                            </Box>
                            <Box flex="2">
                                <Select
                                    borderRadius={'full'}
                                    fontSize={'13'}
                                    value={formData.englishCommunication}
                                    onChange={handleChange}
                                    name='englishCommunication'
                                    borderColor={'gray'}
                                    placeholder='Select English Communication Skill'
                                >
                                    <option value='beginner'>Beginner</option>
                                    <option value='moderate'>Moderate</option>
                                    <option value='fluent'>Fluent</option>
                                </Select>
                            </Box>
                        </HStack>

                        <HStack spacing={4} mb={4}>
                            <Box flex="1">
                                <FormLabel fontSize={'sm'}>Career Objective:</FormLabel>
                            </Box>
                            <Box flex="2">
                                <Textarea
                                    borderRadius={'full'}
                                    p={4}
                                    fontSize={'13'}
                                    borderColor={'gray'}
                                    name='careerObjective'
                                    value={formData.careerObjective}
                                    placeholder='In one line, please write your career objective:'
                                    onChange={handleChange}
                                />
                            </Box>
                        </HStack>

                        <HStack spacing={4} mt={4} justifyContent={'space-between'}>
                            <Button colorScheme='teal' onClick={handleClear} boxShadow={'md'} borderRadius={'md'}>Clear</Button>
                            <Button colorScheme='blue' onClick={handleSubmit} boxShadow={'md'} borderRadius={'md'}>Submit</Button>
                        </HStack>
                    </FormControl>
                </Box>
            </Flex>
        </Flex>
    );
}

export default Form;
